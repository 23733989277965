var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { MAINTAIN_PERMISSION, EDIT_PERMISSION, STERILIZATION_SPECIFICATIONS_DATA_ENTRY_DETAILS_COOLING, STERILIZATION_SPECIFICATIONS_DATA_ENTRY, FILTERS_EQUAL, ATTACH_PERMISSION, STERILIZATION_SPECIFICATIONS_DATA_ENTRY_BRITESTACK, STERILIZATION_SPECIFICATIONS_DATA_ENTRY_BRITESTACK_TRIAL, DELETE_PERMISSION, STERILIZATION_SPECIFICATIONS_DATA_ENTRY_NO_OR_TRIAL_ITEMS, STERILIZATION_SPECIFICATIONS_DATA_ENTRY_NOTES2, VIEW_PERMISSION, STERILIZATION_SPECIFICATIONS_DATA_ENTRY_ATTACHMENT } from "@kraftheinz/common";
import _ from "lodash";
import { n as normalizeComponent } from "./index.js";
import numeral from "numeral";
const STERILIZATION_METHODS = {
  PETFOOD_STERIFLOW_RETORT: 1,
  PETFOOD_HYDROSTATIC_COOKER: 2,
  GTC_PASTEURISER: 3,
  STERIFLOW: 4,
  STATIC_RETORT_RECIPE: 5,
  ROTARY_RETORT: 6,
  STATIC_RETORT_SEASONAL: 7,
  RECIPE_RETORT: 8,
  OVERPRESSURE_RETORT: 9,
  HYDROSTATIC_COOKER: 10,
  CONTINUOUS_COOKER: 11,
  LAGARDE_RETORTS: 12,
  KINGSTREET_STERIFLOW: 13,
  PETFOOD_STERITECH_STERILISER: 14
};
const CHAINS = {
  INNER: "I",
  OUTER: "O"
};
const CHAIN_OPTIONS = [
  {
    text: "Outer",
    value: CHAINS.OUTER
  },
  {
    text: "Inner",
    value: CHAINS.INNER
  }
];
var render$g = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "cooker-form" }, [_c("a-row", { attrs: { "gutter": 24 } }, [_c("a-col", { attrs: { "span": 12 } }, [_c("a-row", { attrs: { "gutter": 24 } }, [_c("a-col", { attrs: { "span": 4 } }, [_c("select-input", { attrs: { "all-in-value": "", "disabled": !_vm.editable, "label": "Cooker ID", "reference": "sterilization.common.cooker-capacities", "source": "id", "source-label": "cookerNo", "value": _vm.editForm.cookerCapacityId }, on: { "change": _vm.onCookerCapacityChange } })], 1), _c("a-col", { attrs: { "span": 6 } }, [_c("text-input", { attrs: { "disabled": "", "label": "Can Size", "value": _vm.editForm.canSize } })], 1), _c("a-col", { attrs: { "span": 4 } }, [_c("text-input", { attrs: { "disabled": _vm.isCpmDisabled, "label": "CPM", "value": _vm.editForm.cpm }, on: { "change": _vm.onCpmChange } })], 1), _c("a-col", { attrs: { "span": 5 } }, [_c("text-input", { attrs: { "disabled": !_vm.editable, "label": "Temp", "value": _vm.editForm.temp }, on: { "change": function($event) {
    return _vm.storeValue("temp", $event);
  } } })], 1), _c("a-col", { attrs: { "span": 5 } }, [_c("text-input", { attrs: { "disabled": !_vm.editable, "label": "Initial Temp", "value": _vm.editForm.initialTemp }, on: { "change": function($event) {
    return _vm.storeValue("initialTemp", $event);
  } } })], 1)], 1)], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("a-row", { attrs: { "gutter": 24 } }, [_c("a-col", { attrs: { "span": 5 } }, [_c("text-input", { attrs: { "disabled": _vm.isCookerTimeDisabled, "label": "Cooker", "value": _vm.editForm.cTime }, on: { "change": _vm.onCookerTimeChange } })], 1), _c("a-col", { attrs: { "span": 4 } }, [_c("text-input", { attrs: { "disabled": "", "label": "P.C.", "value": _vm.editForm.pcTime } })], 1), _c("a-col", { attrs: { "span": 4 } }, [_c("text-input", { attrs: { "disabled": "", "label": "A.C.", "value": _vm.editForm.acTime } })], 1), _c("a-col", { attrs: { "span": 5 } }, [_c("text-input", { attrs: { "disabled": !_vm.editable, "label": "Over Process", "value": _vm.editForm.opTime }, on: { "change": function($event) {
    return _vm.storeValue("opTime", $event);
  } } })], 1), _c("a-col", { attrs: { "span": 6 } }, [_c("text-input", { attrs: { "disabled": "", "label": "Total", "value": _vm.editForm.totTime } })], 1)], 1)], 1)], 1), _c("a-divider", { staticClass: "my-3" }), _c("a-row", { attrs: { "align": "bottom", "gutter": 24 } }, [_c("a-col", { attrs: { "span": 12 } }, [_c("a-row", { attrs: { "gutter": 24 } }, [_c("a-col", { attrs: { "span": 10 } }, [_c("text-input", { staticClass: "mb-3", attrs: { "disabled": !_vm.editable, "label": "P.C. Inlet Temp", "value": _vm.editForm.pcInletTemp }, on: { "change": function($event) {
    return _vm.storeValue("pcInletTemp", $event);
  } } })], 1), _c("a-col", { attrs: { "span": 4 } }, [_c("text-input", { staticClass: "mb-3", attrs: { "disabled": !_vm.editable, "label": "PSI", "value": _vm.editForm.psi }, on: { "change": function($event) {
    return _vm.storeValue("psi", $event);
  } } })], 1), _c("a-col", { attrs: { "span": 10 } }, [_c("text-input", { staticClass: "mb-3", attrs: { "disabled": !_vm.editable, "label": "A.C. Inlet Temp", "value": _vm.editForm.acInletTemp }, on: { "change": function($event) {
    return _vm.storeValue("acInletTemp", $event);
  } } })], 1), _c("a-col", { attrs: { "span": 10 } }, [_c("text-input", { attrs: { "disabled": !_vm.editable, "label": "P.C. Outlet Temp", "value": _vm.editForm.pcOutletTemp }, on: { "change": function($event) {
    return _vm.storeValue("pcOutletTemp", $event);
  } } })], 1), _c("a-col", { attrs: { "span": 4 } }, [_c("select-input", { attrs: { "data-source": _vm.chainOptions, "disabled": _vm.isChainDisabled, "label": "Chain", "source": "value", "source-label": "text", "value": _vm.editForm.chain }, on: { "change": _vm.onChainChange } })], 1), _c("a-col", { attrs: { "span": 10 } }, [_c("text-input", { attrs: { "disabled": !_vm.editable, "label": "A.C. Outlet Temp", "value": _vm.editForm.acOutletTemp }, on: { "change": function($event) {
    return _vm.storeValue("acOutletTemp", $event);
  } } })], 1)], 1)], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("a-row", { attrs: { "gutter": 24 } }, [_c("a-col", { attrs: { "offset": 6, "span": 18 } }, [_c("div", { staticClass: "cooker-form__heading pb-3 h8" }, [_vm._v("FLIGHT TIMING")])]), _c("a-col", { attrs: { "span": 6 } }, [_c("div", { staticClass: "cooker-form__sub-heading h8" }, [_vm._v("TIME PER 10 FLITES")]), _c("a-divider", { staticClass: "my-2" }), _c("a-row", { attrs: { "gutter": 16 } }, [_c("a-col", { attrs: { "span": 12 } }, [_c("text-input", { attrs: { "disabled": !_vm.editable, "label": "Mins.", "value": _vm.editForm.timePer10FlitesMins }, on: { "change": function($event) {
    return _vm.storeValue("timePer10FlitesMins", $event);
  } } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("text-input", { attrs: { "disabled": !_vm.editable, "label": "Secs.", "value": _vm.editForm.timePer10FlitesSecs }, on: { "change": function($event) {
    return _vm.storeValue("timePer10FlitesSecs", $event);
  } } })], 1)], 1)], 1), _c("a-col", { attrs: { "span": 6 } }, [_c("div", { staticClass: "cooker-form__sub-heading h8" }, [_vm._v("MIN")]), _c("a-divider", { staticClass: "my-2" }), _c("a-row", { attrs: { "gutter": 16 } }, [_c("a-col", { attrs: { "span": 12 } }, [_c("text-input", { attrs: { "disabled": _vm.isFlightTimingDisabled, "label": "Mins.", "value": _vm.editForm.flightTimingMinMins }, on: { "change": function($event) {
    return _vm.storeValue("flightTimingMinMins", $event);
  } } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("text-input", { attrs: { "disabled": _vm.isFlightTimingDisabled, "label": "Secs.", "value": _vm.editForm.flightTimingMinSecs }, on: { "change": function($event) {
    return _vm.storeValue("flightTimingMinSecs", $event);
  } } })], 1)], 1)], 1), _c("a-col", { attrs: { "span": 6 } }, [_c("div", { staticClass: "cooker-form__sub-heading h8" }, [_vm._v("MAX")]), _c("a-divider", { staticClass: "my-2" }), _c("a-row", { attrs: { "gutter": 16 } }, [_c("a-col", { attrs: { "span": 12 } }, [_c("text-input", { attrs: { "disabled": _vm.isFlightTimingDisabled, "label": "Mins.", "value": _vm.editForm.flightTimingMaxMins }, on: { "change": function($event) {
    return _vm.storeValue("flightTimingMaxMins", $event);
  } } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("text-input", { attrs: { "disabled": _vm.isFlightTimingDisabled, "label": "Secs.", "value": _vm.editForm.flightTimingMaxSecs }, on: { "change": function($event) {
    return _vm.storeValue("flightTimingMaxSecs", $event);
  } } })], 1)], 1)], 1), _c("a-col", { style: { paddingTop: "35px" }, attrs: { "span": 6 } }, [_c("text-input", { attrs: { "disabled": _vm.isFlightTimingDisabled, "label": "Flight Count", "value": _vm.editForm.flightCount }, on: { "change": function($event) {
    return _vm.storeValue("flightCount", $event);
  } } })], 1)], 1)], 1)], 1)], 1);
};
var staticRenderFns$g = [];
var CookerForm_vue_vue_type_style_index_0_scoped_true_lang = "";
const __vue2_script$g = {
  name: "CookerForm",
  inject: ["resourceName", "redirectRoute", "crud"],
  props: {
    methodId: {
      type: Number,
      required: true
    },
    cooker: {
      type: Object,
      default: null
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      chainOptions: CHAIN_OPTIONS,
      editForm: __spreadValues({}, this.cooker),
      cookerCapacity: null
    };
  },
  computed: {
    cookerCapacities() {
      return this.$store.getters["sterilization.common.cooker-capacities/list"];
    },
    isChainDisabled() {
      const {
        HYDROSTATIC_COOKER,
        PETFOOD_HYDROSTATIC_COOKER
      } = STERILIZATION_METHODS;
      return !this.editable || !((this.methodId == HYDROSTATIC_COOKER || this.methodId == PETFOOD_HYDROSTATIC_COOKER) && this.isHydro);
    },
    isCpmDisabled() {
      return !this.editable || this.isHydro;
    },
    isCookerTimeDisabled() {
      return !this.editable || !this.isHydro;
    },
    isFlightTimingDisabled() {
      return !this.editable || this.selectedCookerCapacity.cooker != "10";
    },
    isHydro() {
      return this.selectedCookerCapacity.hydro == -1;
    },
    selectedCookerCapacity() {
      if (this.cookerCapacity) {
        return this.cookerCapacity;
      }
      return this.cookerCapacities.find((cookerCap) => this.editForm.cookerCapacityId == cookerCap.id) || {};
    }
  },
  watch: {
    editable(newVal) {
      if (!newVal) {
        this.editForm = __spreadValues({}, this.cooker);
      }
    },
    cooker(newVal) {
      this.editForm = newVal;
    }
  },
  methods: {
    storeValue(resourceKey, value) {
      this.editForm = __spreadProps(__spreadValues({}, this.editForm), {
        [resourceKey]: value
      });
      this.$emit("change", { resourceKey, value });
    },
    calculateTimingForHydroCooker(cTime, chain) {
      let {
        acCapacity,
        capacity,
        capacityInner,
        pcCapacity,
        totalCapacity,
        totalCapacityInner
      } = this.selectedCookerCapacity;
      if (cTime < 20) {
        acCapacity /= 2;
        capacity /= 2;
        capacityInner /= 2;
        pcCapacity /= 2;
        totalCapacity /= 2;
        totalCapacityInner /= 2;
      }
      let _capacity, _totalCapacity;
      if ((this.methodId == STERILIZATION_METHODS.HYDROSTATIC_COOKER || this.methodId == STERILIZATION_METHODS.PETFOOD_HYDROSTATIC_COOKER) && chain == CHAINS.INNER) {
        _capacity = capacityInner;
        _totalCapacity = totalCapacityInner;
      } else {
        _capacity = capacity;
        _totalCapacity = totalCapacity;
      }
      const cpm = cTime ? Math.floor(_capacity / cTime) : null;
      const pcTime = cpm ? pcCapacity / cpm : null;
      const acTime = cpm ? acCapacity / cpm : null;
      const totTime = cpm ? _totalCapacity / cpm : null;
      return { cpm, pcTime, acTime, totTime };
    },
    updateTiming({ cpm, cTime, pcTime, acTime, totTime }) {
      this.editForm = __spreadProps(__spreadValues({}, this.editForm), {
        cpm,
        cTime,
        pcTime,
        acTime,
        totTime
      });
      this.storeValue("cpm", cpm);
      this.storeValue("cTime", cTime);
      this.storeValue("pcTime", pcTime);
      this.storeValue("acTime", acTime);
      this.storeValue("totTime", totTime);
    },
    onChainChange(chain) {
      this.storeValue("chain", chain);
      const { cTime } = this.editForm;
      const {
        cpm,
        pcTime,
        acTime,
        totTime
      } = this.calculateTimingForHydroCooker(cTime, chain);
      this.updateTiming({ cpm, cTime, pcTime, acTime, totTime });
    },
    onCookerCapacityChange(cookerCapacity) {
      this.cookerCapacity = cookerCapacity;
      this.storeValue("canSize", cookerCapacity.canSize);
      this.storeValue("cookerCapacityId", cookerCapacity.id);
      this.storeValue("cookerNo", cookerCapacity.cookerNo);
    },
    onCpmChange(cpm) {
      const { acCapacity, capacity, pcCapacity } = this.selectedCookerCapacity;
      const cTime = cpm ? capacity / cpm : null;
      const pcTime = cpm ? pcCapacity / cpm : null;
      const acTime = cpm ? acCapacity / cpm : null;
      const totTime = cpm ? (capacity + pcCapacity + acCapacity) / cpm : null;
      this.updateTiming({ cpm, cTime, pcTime, acTime, totTime });
    },
    onCookerTimeChange(cTime) {
      const chain = this.editForm.chain && this.editForm.chain.trim();
      const {
        cpm,
        pcTime,
        acTime,
        totTime
      } = this.calculateTimingForHydroCooker(cTime, chain);
      this.updateTiming({ cpm, cTime, pcTime, acTime, totTime });
    }
  }
};
const __cssModules$g = {};
var __component__$g = /* @__PURE__ */ normalizeComponent(__vue2_script$g, render$g, staticRenderFns$g, false, __vue2_injectStyles$g, "d9679782", null, null);
function __vue2_injectStyles$g(context) {
  for (let o in __cssModules$g) {
    this[o] = __cssModules$g[o];
  }
}
var CookerForm = /* @__PURE__ */ function() {
  return __component__$g.exports;
}();
var render$f = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "list" }, [_c("div", { staticClass: "list__basic-filters d-flex justify-content-between mb-3" }, [_c("active-filter", { staticClass: "mr-auto", attrs: { "custom-url": _vm.cookerApiUrl } }), _vm.canEditCooling ? _c("a-button", { staticClass: "p-1", on: { "click": _vm.onEditCooling } }, [_vm._v("Edit Cooling")]) : _vm._e(), _vm.isDraft && _vm.canEdit ? _c("a-button", { staticClass: "ml-2 p-1", attrs: { "type": "primary" }, on: { "click": _vm.onAddClick } }, [_vm._v("Add Cooker")]) : _vm._e()], 1), _c("a-table", { attrs: { "bordered": "", "columns": _vm.columns, "custom-row": _vm.customRow, "data-source": _vm.data, "expanded-row-keys": _vm.expandedRowKeys, "pagination": false, "row-key": "id" }, on: { "expandedRowsChange": _vm.onExpandedRowsChange }, scopedSlots: _vm._u([{ key: "action", fn: function(text, record) {
    return [_vm.isDraft && !_vm.isEditable(record) && _vm.canEdit ? [_c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Edit")];
    }, proxy: true }], null, true) }, [_c("a-button", { staticClass: "mr-2", attrs: { "icon": "edit", "size": "small", "type": "link" }, on: { "click": function($event) {
      return _vm.onEditClick(record);
    } } })], 1), _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v(_vm._s(record.isInactive ? "Activate" : "Deactivate"))];
    }, proxy: true }], null, true) }, [_c("a-button", { staticClass: "list__action-toggle-active", attrs: { "icon": record.isInactive ? "eye-invisible" : "eye", "size": "small", "type": "link" }, on: { "click": function($event) {
      return _vm.onToggleActiveClick(record);
    } } })], 1)] : _vm._e(), _vm.isEditable(record) ? [_c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Save")];
    }, proxy: true }], null, true) }, [_c("a-button", { attrs: { "icon": "check", "size": "small", "type": "primary" }, on: { "click": function($event) {
      return _vm.onSaveClick(record);
    } } })], 1), _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Cancel")];
    }, proxy: true }], null, true) }, [_c("a-button", { staticClass: "ml-2", attrs: { "icon": "close", "size": "small" }, on: { "click": _vm.onCancelClick } })], 1)] : _vm._e()];
  } }, { key: "expandedRowRender", fn: function(record) {
    return [_c("cooker-form", { attrs: { "cooker": record, "editable": _vm.isEditable(record), "method-id": _vm.methodId }, on: { "change": _vm.onCookerFormChange } })];
  } }]) })], 1);
};
var staticRenderFns$f = [];
const __vue2_script$f = {
  name: "EditCookers",
  components: {
    CookerForm
  },
  inject: ["can", "crud", "resourceName"],
  props: {
    canEdit: {
      type: Boolean,
      required: true
    },
    cookerApiUrl: {
      type: String,
      required: true
    },
    methodId: {
      type: Number,
      required: true
    },
    sterilizationData: {
      type: Object,
      required: true
    }
  },
  data() {
    const steriId = this.$route.params.id;
    const columns2 = [
      {
        dataIndex: "cookerNo",
        key: "cookerNo",
        title: "Cooker ID"
      },
      {
        dataIndex: "canSize",
        key: "canSize",
        title: "Can Size"
      },
      {
        dataIndex: "cpm",
        key: "cpm",
        title: "CPM"
      },
      {
        dataIndex: "temp",
        key: "temp",
        title: "Temp"
      },
      {
        dataIndex: "initialTemp",
        key: "initialTemp",
        title: "Initial Temp"
      },
      {
        dataIndex: "cTime",
        key: "cTime",
        title: "Cooker"
      },
      {
        dataIndex: "pcTime",
        key: "pcTime",
        title: "P.C."
      },
      {
        dataIndex: "acTime",
        key: "acTime",
        title: "A.C."
      },
      {
        dataIndex: "opTime",
        key: "opTime",
        title: "Overprocess"
      },
      {
        dataIndex: "totTime",
        key: "totTime",
        title: "Total"
      },
      {
        key: "action",
        title: "Action",
        width: 78,
        scopedSlots: { customRender: "action" }
      }
    ];
    return {
      columns: columns2,
      data: [],
      editingId: -1,
      expandedRowKeys: [],
      steriId
    };
  },
  computed: {
    canEditCooling() {
      if (this.sterilizationData.methodId != STERILIZATION_METHODS.CONTINUOUS_COOKER) {
        return false;
      }
      if (this.isDraft)
        return false;
      if (this.can(MAINTAIN_PERMISSION))
        return true;
      if (this.$can(EDIT_PERMISSION, STERILIZATION_SPECIFICATIONS_DATA_ENTRY_DETAILS_COOLING))
        return true;
      return false;
    },
    createForm() {
      return this.crud.getEntityForm("createForm");
    },
    isDraft() {
      return this.sterilizationData.isDraft;
    },
    resourceList() {
      return this.crud.getList();
    }
  },
  watch: {
    cookerApiUrl(newVal) {
      this.fetchList(newVal);
    },
    resourceList(newVal) {
      this.data = newVal;
    },
    async sterilizationData(newVal) {
      if (this.editingId == -1) {
        return;
      }
      if (!newVal.isDraft) {
        if (this.editingId == 0 && this._.isEmpty(this.createForm)) {
          this.editingId = -1;
          this.expandedRowKeys = this.expandedRowKeys.slice(1);
          this.data = this.data.slice(1);
          return;
        }
        const record = this.data.find((cooker) => cooker.id == this.editingId);
        this.onSaveClick(record);
      }
    }
  },
  created() {
    this.fetchList(this.cookerApiUrl);
  },
  methods: {
    customRow(record) {
      if (record.isInactive) {
        return { class: "list__item--inactive" };
      }
      return {};
    },
    async fetchList(cookerApiUrl) {
      const res = await this.crud.fetchList(cookerApiUrl);
      this.data = res.data;
    },
    isEditable(record) {
      return this.editingId == record.id;
    },
    toggleActive(record) {
      return async () => {
        await this.crud.submitPartialEntity({
          resourceName: this.resourceName,
          customUrl: `${this.cookerApiUrl}/${record.id}`,
          data: [
            {
              op: "replace",
              path: "/IsInactive",
              value: !record.isInactive
            }
          ]
        });
        this.fetchList(this.cookerApiUrl);
      };
    },
    onActiveFilterChange() {
      this.editingId = -1;
    },
    onAddClick() {
      if (this.editingId == 0)
        return;
      const cooker = { id: 0 };
      this.data = [cooker, ...this.data];
      this.expandedRowKeys = [0, ...this.expandedRowKeys];
      this.editingId = 0;
      this.$emit("change", true);
      this.crud.initEntity();
    },
    onEditCooling() {
      this.$router.push(`/sterilization-specifications/data-entries/${this.steriId}/edit-cooling`);
    },
    onCancelClick() {
      if (this.editingId == 0) {
        this.data = this.data.slice(1);
        this.expandedRowKeys = this.expandedRowKeys.slice(1);
      }
      this.editingId = -1;
      this.$emit("change", false);
    },
    onCookerFormChange(newVal) {
      if (this.editingId) {
        this.crud.updateEntity("editForm", newVal);
      }
      this.crud.updateEntity("createForm", newVal);
    },
    onEditClick(record) {
      if (this.editingId == 0) {
        this.data = this.data.slice(1);
        this.expandedRowKeys = this.expandedRowKeys.filter((key) => key != 0);
        this.crud.clearEntity("createForm");
      }
      this.editingId = record.id;
      this.$emit("change", true);
      if (!this.expandedRowKeys.includes(this.editingId)) {
        this.$nextTick(() => this.expandedRowKeys = [...this.expandedRowKeys, this.editingId]);
      }
      Object.entries(record).forEach(([resourceKey, value]) => this.crud.updateEntity("editForm", { resourceKey, value }));
    },
    onExpandedRowsChange(expandedRows) {
      this.expandedRowKeys = expandedRows;
      if (expandedRows.includes(this.editingId)) {
        this.editingId = -1;
      }
    },
    async onSaveClick(record) {
      let action, formType;
      if (record.id) {
        action = "replace";
        formType = "editForm";
      } else {
        action = "create";
        formType = "createForm";
      }
      if (action == "replace") {
        const entityForm = this.crud.getEntityForm(formType);
        Object.keys(record).forEach((key) => {
          if (!entityForm[key]) {
            this.crud.updateEntity(formType, {
              resourceKey: key,
              value: record[key]
            });
          }
        });
      }
      const url = action == "replace" ? `${this.cookerApiUrl}/${record.id}` : this.cookerApiUrl;
      await this.crud.submitEntity(action, url);
      this.editingId = -1;
      this.fetchList(this.cookerApiUrl);
      this.$emit("change", false);
    },
    onToggleActiveClick(record) {
      let icon, okType, title;
      if (record.isInactive) {
        title = "Do you want to Activate this item?";
        icon = "eye";
        okType = "primary";
      } else {
        title = "Do you want to Deactivate this item?";
        icon = "eye-invisible";
        okType = "danger";
      }
      this.$confirm({
        class: "list__modal-confirm",
        icon,
        title,
        cancelText: "No",
        okText: "Yes",
        okType,
        onOk: this.toggleActive(record)
      });
    }
  }
};
const __cssModules$f = {};
var __component__$f = /* @__PURE__ */ normalizeComponent(__vue2_script$f, render$f, staticRenderFns$f, false, __vue2_injectStyles$f, null, null, null);
function __vue2_injectStyles$f(context) {
  for (let o in __cssModules$f) {
    this[o] = __cssModules$f[o];
  }
}
var EditCookers$1 = /* @__PURE__ */ function() {
  return __component__$f.exports;
}();
var render$e = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "edit-cookers p-5" }, [_c("resource", { attrs: { "name": "sterilization.common.cooker-capacities", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "sterilization.data-entries.cookers", "api-url": _vm.apiUrl, "page": _vm.STERILIZATION_SPECIFICATIONS_DATA_ENTRY, "redirect-route": "/sterilization-specifications/data-entries" } }, [_c("edit-cookers", { attrs: { "can-edit": _vm.canEdit, "cooker-api-url": _vm.cookerApiUrl, "method-id": _vm.methodId, "sterilization-data": _vm.sterilizationData }, on: { "change": _vm.onChange } })], 1)], 1);
};
var staticRenderFns$e = [];
const __vue2_script$e = {
  components: {
    EditCookers: EditCookers$1
  },
  props: {
    canEdit: {
      type: Boolean,
      required: true
    },
    dataEntryId: {
      type: Number,
      required: true
    },
    methodId: {
      type: Number,
      required: true
    },
    sterilizationData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      STERILIZATION_SPECIFICATIONS_DATA_ENTRY,
      apiUrl: "#{VUE_APP_API_URL}#"
    };
  },
  computed: {
    cookerApiUrl() {
      return `${this.apiUrl}/sterilization/data-entries/${this.dataEntryId}/cookers`;
    }
  },
  methods: {
    onChange(e) {
      this.$emit("change", e);
    }
  }
};
const __cssModules$e = {};
var __component__$e = /* @__PURE__ */ normalizeComponent(__vue2_script$e, render$e, staticRenderFns$e, false, __vue2_injectStyles$e, null, null, null);
function __vue2_injectStyles$e(context) {
  for (let o in __cssModules$e) {
    this[o] = __cssModules$e[o];
  }
}
var EditCookers = /* @__PURE__ */ function() {
  return __component__$e.exports;
}();
var render$d = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("a-table", { staticClass: "p-5", attrs: { "bordered": "", "columns": _vm.columns, "data-source": _vm.resourceList, "loading": _vm.isLoading, "pagination": false, "row-key": "index" }, scopedSlots: _vm._u([{ key: "rotaryInd", fn: function(value, record) {
    return [_c("a-checkbox", { attrs: { "checked": record.rotaryInd, "disabled": !_vm.editMode }, on: { "change": function($event) {
      return _vm.storeValue($event.target.checked, "rotaryInd");
    } } })];
  } }, { key: "canSize", fn: function(text, record) {
    return [_vm.editMode ? _c("div", [_c("select-input", { attrs: { "advanced-filters": _vm.canSizesAdvancedFilters, "delete-filter": "IsInactive", "lazy": "", "placeholder": "Select Can Size", "reference": "sterilization.common.can-sizes", "source": "id", "source-label": "name", "value": record.canSizeId || null }, on: { "change": function($event) {
      return _vm.storeValue($event, "canSizeId");
    } } })], 1) : _c("div", [_vm._v(_vm._s(text))])];
  } }, { key: "iTempRange", fn: function(item) {
    return [_vm.editMode ? _c("div", [_c("text-input", { attrs: { "value": item.iTempRange1, "suffix": "\xB0C" }, on: { "change": function($event) {
      return _vm.storeValue($event, "iTempRange1");
    } } }), _c("text-input", { staticClass: "my-3", attrs: { "value": item.iTempRange2, "suffix": "\xB0C" }, on: { "change": function($event) {
      return _vm.storeValue($event, "iTempRange2");
    } } }), _c("text-input", { attrs: { "value": item.iTempRange3, "suffix": "\xB0C" }, on: { "change": function($event) {
      return _vm.storeValue($event, "iTempRange3");
    } } })], 1) : _c("div", [_c("p", [_vm._v(_vm._s(item.iTempRange1 ? item.iTempRange1 + "\xB0C" : "-"))]), _c("p", [_vm._v(_vm._s(item.iTempRange2 ? item.iTempRange2 + "\xB0C" : "-"))]), _c("p", [_vm._v(_vm._s(item.iTempRange3 ? item.iTempRange3 + "\xB0C" : "-"))])])];
  } }, { key: "pTemp", fn: function(text) {
    return [_vm.editMode ? _c("div", [_c("text-input", { attrs: { "value": text }, on: { "change": function($event) {
      return _vm.storeValue($event, "pTemp");
    } } })], 1) : _c("div", [_vm._v(_vm._s(text))])];
  } }, { key: "time", fn: function(item) {
    return [_vm.editMode ? _c("div", [_c("text-input", { attrs: { "value": item.time1 }, on: { "change": function($event) {
      return _vm.storeValue($event, "time1");
    } } }), _c("text-input", { staticClass: "my-3", attrs: { "value": item.time2 }, on: { "change": function($event) {
      return _vm.storeValue($event, "time2");
    } } }), _c("text-input", { attrs: { "value": item.time3 }, on: { "change": function($event) {
      return _vm.storeValue($event, "time3");
    } } })], 1) : _c("div", [_c("p", [_vm._v(_vm._s(item.time1 || "-"))]), _c("p", [_vm._v(_vm._s(item.time2 || "-"))]), _c("p", [_vm._v(_vm._s(item.time3 || "-"))])])];
  } }, { key: "coolTime8_11", fn: function(text) {
    return [_vm.editMode ? _c("div", [_c("text-input", { attrs: { "value": text }, on: { "change": function($event) {
      return _vm.storeValue($event, "coolTime8_11");
    } } })], 1) : _c("div", [_vm._v(_vm._s(text))])];
  } }, { key: "coolTime12_17", fn: function(text) {
    return [_vm.editMode ? _c("div", [_c("text-input", { attrs: { "value": text }, on: { "change": function($event) {
      return _vm.storeValue($event, "coolTime12_17");
    } } })], 1) : _c("div", [_vm._v(_vm._s(text))])];
  } }, { key: "action", fn: function() {
    return [_c("div", { staticClass: "align-center" }, [_vm.isDraft && !_vm.editMode && _vm.canEdit ? _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Edit")];
    }, proxy: true }], null, false, 1178716417) }, [_c("a-button", { attrs: { "icon": "edit", "size": "small", "type": "link" }, on: { "click": _vm.onEditClick } })], 1) : _vm._e(), _vm.editMode ? _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Save")];
    }, proxy: true }], null, false, 2119197308) }, [_c("a-button", { staticClass: "mr-2", attrs: { "type": "primary", "icon": "check", "size": "small" }, on: { "click": _vm.onSave } })], 1) : _vm._e(), _vm.editMode ? _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Cancel")];
    }, proxy: true }], null, false, 1421338523) }, [_c("a-button", { attrs: { "icon": "close", "size": "small" }, on: { "click": _vm.onCancelClick } })], 1) : _vm._e()], 1)];
  }, proxy: true }]) });
};
var staticRenderFns$d = [];
var EditRotaryRetort_vue_vue_type_style_index_0_scoped_true_lang = "";
const __vue2_script$d = {
  name: "EditRotaryRetort",
  inject: ["crud"],
  props: {
    canEdit: {
      type: Boolean,
      required: true
    },
    rotaryRetortsApiUrl: {
      type: String,
      required: true
    },
    sterilizationData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      columns: [
        {
          title: "Rotary",
          align: "center",
          dataIndex: "rotaryInd",
          width: "5%",
          scopedSlots: { customRender: "rotaryInd" }
        },
        {
          title: "Can Size",
          dataIndex: "canSize",
          width: "11%",
          scopedSlots: { customRender: "canSize" }
        },
        {
          title: "Initial Temp (\u02DAC)",
          dataIndex: "iTempRange",
          width: "12%",
          scopedSlots: { customRender: "iTempRange" }
        },
        {
          title: "Process Temp (\u02DAC)",
          align: "right",
          dataIndex: "pTemp",
          width: "12%",
          scopedSlots: { customRender: "pTemp" }
        },
        {
          title: "Process Time (mins)",
          align: "right",
          dataIndex: "time",
          width: "12%",
          scopedSlots: { customRender: "time" }
        },
        {
          title: "Cooling Time (mins) Retorts 8 - 11",
          align: "right",
          dataIndex: "coolTime8_11",
          width: "12%",
          scopedSlots: { customRender: "coolTime8_11" }
        },
        {
          title: "Cooling Time (mins) Retorts 12 - 17",
          align: "right",
          dataIndex: "coolTime12_17",
          width: "12%",
          scopedSlots: { customRender: "coolTime12_17" }
        },
        {
          title: "Action",
          align: "center",
          width: "6%",
          scopedSlots: { customRender: "action" }
        }
      ],
      canSizesAdvancedFilters: [
        ["Facility", { operator: FILTERS_EQUAL, value: "PK,PP,PT" }]
      ],
      editingKey: "",
      entity: {},
      isLoading: true,
      editMode: false,
      apiUrl: "#{VUE_APP_API_URL}#"
    };
  },
  computed: {
    actionType() {
      return this.entity.id ? "replace" : "create";
    },
    formType() {
      return this.entity.id ? "editForm" : "createForm";
    },
    isDraft() {
      return this.sterilizationData.isDraft;
    },
    resourceList() {
      const {
        canSize,
        canSizeId,
        iTempRange1,
        iTempRange2,
        iTempRange3,
        time1,
        time2,
        time3,
        pTemp,
        coolTime8_11,
        coolTime12_17,
        rotaryInd
      } = this.entity;
      return [
        {
          index: 1,
          rotaryInd,
          canSize,
          canSizeId,
          iTempRange: {
            iTempRange1,
            iTempRange2,
            iTempRange3
          },
          pTemp,
          time: {
            time1,
            time2,
            time3
          },
          coolTime8_11,
          coolTime12_17
        }
      ];
    }
  },
  watch: {
    isDraft(newVal) {
      if (newVal)
        return;
      if (this.editMode) {
        this.onSave();
      }
    },
    rotaryRetortsApiUrl(newVal) {
      this.fetchEntity(newVal);
    }
  },
  async mounted() {
    this.fetchEntity(this.rotaryRetortsApiUrl);
  },
  methods: {
    async fetchEntity(rotaryRetortsApiUrl) {
      const res = await this.crud.fetchEntity(rotaryRetortsApiUrl);
      this.storeValues(res.data);
      this.isLoading = false;
    },
    storeValue(value, resourceKey) {
      this.entity = __spreadProps(__spreadValues({}, this.entity), {
        [resourceKey]: value
      });
      this.crud.updateEntity(this.formType, { resourceKey, value });
    },
    storeValues(entity) {
      const fields = Object.keys(entity);
      fields.forEach((key) => this.storeValue(entity[key], key));
    },
    onCancelClick() {
      this.editMode = false;
      this.entity = this.crud.getEntity(this.entity.id);
      this.storeValues(this.entity);
      this.$emit("change", false);
    },
    onEditClick() {
      this.editMode = true;
      this.$emit("change", true);
    },
    onSave() {
      this.isLoading = true;
      this.crud.submitEntity(this.actionType, this.rotaryRetortsApiUrl).then(async () => {
        this.entity = (await this.crud.fetchEntity(this.rotaryRetortsApiUrl)).data;
      }).finally(() => {
        this.isLoading = false;
        this.editMode = false;
        this.$emit("change", false);
      });
    }
  }
};
const __cssModules$d = {};
var __component__$d = /* @__PURE__ */ normalizeComponent(__vue2_script$d, render$d, staticRenderFns$d, false, __vue2_injectStyles$d, "a887f8b6", null, null);
function __vue2_injectStyles$d(context) {
  for (let o in __cssModules$d) {
    this[o] = __cssModules$d[o];
  }
}
var EditRotaryRetort$1 = /* @__PURE__ */ function() {
  return __component__$d.exports;
}();
var render$c = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "edit-rotary-retort" }, [_c("resource", { attrs: { "name": "sterilization.common.can-sizes", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "sterilization.data-entries.rotary-retorts", "api-url": _vm.apiUrl, "redirect-route": "/sterilization-specifications/data-entries" } }, [_c("edit-rotary-retort", { attrs: { "can-edit": _vm.canEdit, "rotary-retorts-api-url": _vm.rotaryRetortsApiUrl, "sterilization-data": _vm.sterilizationData }, on: { "change": _vm.onChange } })], 1)], 1);
};
var staticRenderFns$c = [];
const __vue2_script$c = {
  components: {
    EditRotaryRetort: EditRotaryRetort$1
  },
  props: {
    canEdit: {
      type: Boolean,
      required: true
    },
    dataEntryId: {
      type: Number,
      required: true
    },
    sterilizationData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      apiUrl: "#{VUE_APP_API_URL}#"
    };
  },
  computed: {
    rotaryRetortsApiUrl() {
      return `${this.apiUrl}/sterilization/data-entries/${this.dataEntryId}/rotary-retorts`;
    }
  },
  methods: {
    onChange(e) {
      this.$emit("change", e);
    }
  }
};
const __cssModules$c = {};
var __component__$c = /* @__PURE__ */ normalizeComponent(__vue2_script$c, render$c, staticRenderFns$c, false, __vue2_injectStyles$c, null, null, null);
function __vue2_injectStyles$c(context) {
  for (let o in __cssModules$c) {
    this[o] = __cssModules$c[o];
  }
}
var EditRotaryRetort = /* @__PURE__ */ function() {
  return __component__$c.exports;
}();
var render$b = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", { attrs: { "custom-url": _vm.customUrl, "hide-pagination": "" }, scopedSlots: _vm._u([{ key: "customButton", fn: function() {
    return [_vm.canAttach ? _c("a-button", { attrs: { "type": "primary" }, on: { "click": _vm.onAttachClick } }, [_vm._v("Attach Britestack")]) : _vm._e()];
  }, proxy: true }, { key: "action", fn: function(ref) {
    var record = ref.record;
    var toggleActive = ref.toggleActive;
    return [_c("div", [_vm.canDeactivate(record) ? _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v(_vm._s(record.isInactive ? "Activate" : "Deactivate"))];
    }, proxy: true }], null, true) }, [_c("a-button", { staticClass: "list__action-toggle-active", attrs: { "icon": record.isInactive ? "eye-invisible" : "eye", "size": "small", "type": "link" }, on: { "click": function($event) {
      return toggleActive(record);
    } } })], 1) : _vm._e()], 1)];
  } }]) }, [_c("text-field", { key: "ItemCode", attrs: { "data-index": "itemCode", "sorter": true, "title": "Item Code" } }), _c("text-field", { key: "ABC Code", attrs: { "data-index": "abcCode", "sorter": true, "title": "ABC Codes" } }), _c("text-field", { key: "Description", attrs: { "data-index": "description", "sorter": true, "title": "Description" } }), _c("text-field", { key: "EffDate", attrs: { "data-index": "effDate", "title": "Effective Date", "sorter": true, "parse": _vm.parseDate } }), _c("text-field", { key: "EndDate", attrs: { "data-index": "endDate", "title": "End Date", "sorter": true, "parse": _vm.parseDate } }), _c("text-field", { key: "UpdatedBy", attrs: { "data-index": "updatedBy", "sorter": true, "title": "Last Change User" } }), _c("text-field", { key: "UpdatedAt", attrs: { "data-index": "updatedAt", "title": "Last Change Date", "sorter": true, "parse": _vm.parseDate } }), _c("text-field", { key: "Notes", attrs: { "data-index": "notes", "sorter": true, "title": "Note" } })], 1);
};
var staticRenderFns$b = [];
const __vue2_script$b = {
  name: "ViewAttachMents",
  inject: ["can"],
  props: {
    apiUrl: {
      type: String,
      required: true
    },
    sterilizationData: {
      type: Object,
      required: true
    },
    attachmentsData: {
      type: Array,
      required: true
    }
  },
  computed: {
    customUrl() {
      return `${this.apiUrl}/sterilization/data-entries/${this.sterilizationData.id}/attachments`;
    },
    canAttach() {
      if (this.sterilizationData.isInactive)
        return false;
      if (this.$can(ATTACH_PERMISSION, STERILIZATION_SPECIFICATIONS_DATA_ENTRY_BRITESTACK) || this.$can(ATTACH_PERMISSION, STERILIZATION_SPECIFICATIONS_DATA_ENTRY_BRITESTACK_TRIAL))
        return true;
      return false;
    },
    canOnlyAttachTrial() {
      if (this.$can(ATTACH_PERMISSION, STERILIZATION_SPECIFICATIONS_DATA_ENTRY_BRITESTACK))
        return "none";
      if (this.$can(ATTACH_PERMISSION, STERILIZATION_SPECIFICATIONS_DATA_ENTRY_BRITESTACK_TRIAL))
        return true;
      return "none";
    },
    hasOnlyTrialAttachments() {
      return this.attachmentsData.every((attachment) => attachment.trialFlag);
    }
  },
  methods: {
    canDeactivate(record) {
      if (this.$can(DELETE_PERMISSION, STERILIZATION_SPECIFICATIONS_DATA_ENTRY_BRITESTACK))
        return true;
      if (this.$can(DELETE_PERMISSION, STERILIZATION_SPECIFICATIONS_DATA_ENTRY_BRITESTACK_TRIAL) && record.trialFlag)
        return true;
      return false;
    },
    parseDate(value) {
      if (!value)
        return "";
      return this.$moment(value).format("DD/MM/YYYY");
    },
    onAttachClick() {
      this.$router.push(`/sterilization-specifications/data-entries/${this.sterilizationData.id}/attach-britestack?trialFlag=${this.canOnlyAttachTrial}`);
    }
  }
};
const __cssModules$b = {};
var __component__$b = /* @__PURE__ */ normalizeComponent(__vue2_script$b, render$b, staticRenderFns$b, false, __vue2_injectStyles$b, null, null, null);
function __vue2_injectStyles$b(context) {
  for (let o in __cssModules$b) {
    this[o] = __cssModules$b[o];
  }
}
var ViewAttachments = /* @__PURE__ */ function() {
  return __component__$b.exports;
}();
var render$a = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "list-attachments" }, [_c("resource", { attrs: { "name": "sterilization.data-entries.attachments", "api-url": _vm.apiUrl } }, [_c("view-attachments", { attrs: { "api-url": _vm.apiUrl, "sterilization-data": _vm.sterilizationData, "attachments-data": _vm.attachmentsData } })], 1)], 1);
};
var staticRenderFns$a = [];
const apiUrl$5 = "#{VUE_APP_API_URL}#";
const __vue2_script$a = {
  components: {
    ViewAttachments
  },
  props: {
    sterilizationData: {
      type: Object,
      required: true
    },
    attachmentsData: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      apiUrl: apiUrl$5
    };
  }
};
const __cssModules$a = {};
var __component__$a = /* @__PURE__ */ normalizeComponent(__vue2_script$a, render$a, staticRenderFns$a, false, __vue2_injectStyles$a, null, null, null);
function __vue2_injectStyles$a(context) {
  for (let o in __cssModules$a) {
    this[o] = __cssModules$a[o];
  }
}
var ListAttachments = /* @__PURE__ */ function() {
  return __component__$a.exports;
}();
var render$9 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", { attrs: { "custom-url": _vm.customUrl, "has-active": false, "has-filter-inactive": false, "hide-pagination": true }, scopedSlots: _vm._u([{ key: "action", fn: function(ref) {
    var record = ref.record;
    return [_c("div", { style: { minHeight: "24px" } }, [_vm.sterilizationData.isDraft && _vm.canEdit ? _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Edit")];
    }, proxy: true }], null, true) }, [_c("a-button", { attrs: { "icon": "edit", "size": "small", "type": "link" }, on: { "click": function($event) {
      return _vm.edit(record);
    } } })], 1) : _vm._e()], 1)];
  } }]) }, [_c("text-field", { key: "CanSize", attrs: { "data-index": "canSize", "title": "Can Size", "sorter": false } }), _c("text-field", { key: "minInitTemp", attrs: { "data-index": "minInitTemp", "title": "Minimum Initial Temp (\xB0C)", "sorter": false, "parse": _vm.parseDecimal } }), _c("text-field", { key: "minPastTemp", attrs: { "data-index": "minPastTemp", "title": "Minimum IPasteuriser Temp (\xB0C)", "sorter": false, "parse": _vm.parseDecimal } }), _c("text-field", { key: "minPastSpeed", attrs: { "data-index": "minPastSpeed", "title": "Minimum IPasteuriser Speed (mins)", "sorter": false, "parse": _vm.parseDecimal } }), _c("text-field", { key: "minWaterLevel", attrs: { "data-index": "minWaterLevel", "title": "Water Level (%min)", "sorter": false, "parse": _vm.parseDecimal } }), _c("text-field", { key: "maxWaterLevel", attrs: { "data-index": "maxWaterLevel", "title": "Water Level (%max)", "sorter": false, "parse": _vm.parseDecimal } })], 1);
};
var staticRenderFns$9 = [];
const __vue2_script$9 = {
  name: "ViewGTCPasteuriser",
  props: {
    canEdit: {
      type: Boolean,
      required: true
    },
    customUrl: {
      type: String,
      required: true
    },
    editRoute: {
      type: String,
      required: true
    },
    sterilizationData: {
      type: Object,
      required: true
    }
  },
  methods: {
    edit(record) {
      this.$router.push(this.editRoute.replace(":id", record.id));
    },
    parseDecimal(value) {
      return numeral(value).format("0.0");
    }
  }
};
const __cssModules$9 = {};
var __component__$9 = /* @__PURE__ */ normalizeComponent(__vue2_script$9, render$9, staticRenderFns$9, false, __vue2_injectStyles$9, null, null, null);
function __vue2_injectStyles$9(context) {
  for (let o in __cssModules$9) {
    this[o] = __cssModules$9[o];
  }
}
var ViewGTCPasteuriser$1 = /* @__PURE__ */ function() {
  return __component__$9.exports;
}();
var render$8 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("resource", { attrs: { "name": "sterilization.common.can-sizes", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "sterilization.data-entries.pasteurisers", "api-url": _vm.apiUrl, "redirect": "" } }, [_c("view-gtc-pasteuriser", { attrs: { "can-edit": _vm.canEdit, "custom-url": _vm.customUrl, "edit-route": _vm.editRoute, "sterilization-data": _vm.sterilizationData } })], 1)], 1);
};
var staticRenderFns$8 = [];
const apiUrl$4 = "#{VUE_APP_API_URL}#";
const __vue2_script$8 = {
  components: {
    "view-gtc-pasteuriser": ViewGTCPasteuriser$1
  },
  props: {
    canEdit: {
      type: Boolean,
      reuired: true
    },
    dataEntryId: {
      type: Number,
      required: true
    },
    sterilizationData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      apiUrl: apiUrl$4
    };
  },
  computed: {
    customUrl() {
      return `${this.apiUrl}/sterilization/data-entries/${this.dataEntryId}/pasteurisers`;
    },
    editRoute() {
      return `/sterilization-specifications/data-entries/${this.dataEntryId}/pasteuriser/:id`;
    }
  }
};
const __cssModules$8 = {};
var __component__$8 = /* @__PURE__ */ normalizeComponent(__vue2_script$8, render$8, staticRenderFns$8, false, __vue2_injectStyles$8, null, null, null);
function __vue2_injectStyles$8(context) {
  for (let o in __cssModules$8) {
    this[o] = __cssModules$8[o];
  }
}
var ViewGTCPasteuriser = /* @__PURE__ */ function() {
  return __component__$8.exports;
}();
var render$7 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_vm._m(0), _c("a-row", { attrs: { "gutter": 16 } }, [_c("a-col", { attrs: { "span": 2 } }, [_c("switch-input", { attrs: { "disabled": !_vm.isDraft || !_vm.canEdit, "label": "Oscillate", "value": _vm.internalEntity.pOscillate }, on: { "change": function($event) {
    return _vm.onChange($event, "pOscillate");
  } } })], 1), _c("a-col", { staticClass: "mt-3", attrs: { "span": 4 } }, [_c("text-input", { attrs: { "disabled": !_vm.isDraft || !_vm.canEdit, "suffix": "degrees", "value": _vm.internalEntity.pOscillateAt }, on: { "change": function($event) {
    return _vm.onChange($event, "pOscillateAt");
  } } })], 1)], 1), _c("a-table", { staticClass: "my-3", attrs: { "bordered": "", "columns": _vm.columns, "data-source": _vm.tableValues, "pagination": false, "row-key": "step" }, scopedSlots: _vm._u([{ key: "action", fn: function(record) {
    return [_vm.isDraft && _vm.canEdit ? _c("div", [record.editable ? _c("div", [_c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Save")];
    }, proxy: true }], null, true) }, [_c("a-button", { staticClass: "mr-2", attrs: { "type": "primary", "icon": "check", "size": "small" }, on: { "click": function($event) {
      return _vm.setEditingStep(0);
    } } })], 1), _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Cancel")];
    }, proxy: true }], null, true) }, [_c("a-button", { attrs: { "icon": "close", "size": "small" }, on: { "click": function($event) {
      return _vm.cancelEditingStep(record.step);
    } } })], 1)], 1) : _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Edit")];
    }, proxy: true }], null, true) }, [_c("a-button", { attrs: { "icon": "edit", "size": "small", "type": "link" }, on: { "click": function($event) {
      return _vm.setEditingStep(record.step);
    } } })], 1)], 1) : _vm._e()];
  } }, { key: "pTemp", fn: function(text, record) {
    return [record.editable ? _c("div", [_c("text-input", { attrs: { "disabled": !_vm.isDraft, "value": text }, on: { "change": function($event) {
      return _vm.storeInputValue($event, "pTemp" + record.step);
    } } })], 1) : _c("div", [_vm._v(_vm._s(text || "-"))])];
  } }, { key: "pPressure", fn: function(text, record) {
    return [record.editable ? _c("div", [_c("text-input", { attrs: { "disabled": !_vm.isDraft, "value": text }, on: { "change": function($event) {
      return _vm.storeInputValue($event, "pPressure" + record.step);
    } } })], 1) : _c("div", [_vm._v(_vm._s(text || "-"))])];
  } }, { key: "rpm", fn: function(text, record) {
    return [record.editable ? _c("div", [_c("text-input", { attrs: { "disabled": !_vm.isDraft, "suffix": "RPM", "value": text }, on: { "change": function($event) {
      return _vm.storeInputValue($event, "rpm" + record.step);
    } } })], 1) : _c("div", [_vm._v(_vm._s(text || "-"))])];
  } }, { key: "pTime", fn: function(text, record) {
    return [record.editable ? _c("div", [_c("text-input", { attrs: { "disabled": !_vm.isDraft, "suffix": "mins", "value": text }, on: { "change": function($event) {
      return _vm.storeInputValue($event, "pTime" + record.step);
    } } })], 1) : _c("div", [_vm._v(_vm._s(text || "-"))])];
  } }]) }), _c("a-row", { attrs: { "gutter": 16 } }, [_c("a-col", { staticClass: "pb-3", attrs: { "span": 6, "md": 10, "lg": 4 } }, [_c("text-input", { attrs: { "disabled": !_vm.isDraft || !_vm.canEdit, "label": "No. of Process Steps", "value": _vm.internalEntity.noPSteps }, on: { "change": function($event) {
    return _vm.onChange($event, "noPSteps");
  } } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("label", [_vm._v("Inital Temperature")]), _c("div", { staticClass: "d-flex w-100" }, [_c("div", { staticClass: "mr-3 w-45" }, [_c("text-input", { attrs: { "disabled": !_vm.isDraft || !_vm.canEdit, "suffix": "\xB0C", "value": _vm.internalEntity.minTemp1 }, on: { "change": _vm.onMinTemp1Change } })], 1), _c("div", { staticClass: "mt-2 mr-2 w-10" }, [_vm._v("to")]), _c("div", { staticClass: "w-45" }, [_c("text-input", { attrs: { "disabled": !_vm.isDraft || !_vm.canEdit, "suffix": "\xB0C", "value": _vm.internalEntity.maxTemp1 }, on: { "change": _vm.onMaxTemp1Change } })], 1)])])], 1)], 1);
};
var staticRenderFns$7 = [function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("p", { staticClass: "title" }, [_c("strong", [_vm._v("Process Time Values")])]);
}];
var index_vue_vue_type_style_index_0_scoped_true_lang$1 = "";
const __vue2_script$7 = {
  name: "OverPressureRetortProcessTimeValues",
  props: {
    canEdit: {
      type: Boolean,
      required: true
    },
    entity: {
      type: Object,
      required: true
    },
    save: {
      type: Function,
      required: true
    },
    storeValue: {
      type: Function,
      required: true
    },
    crud: {
      type: Object,
      required: true
    },
    onMinTemp1Change: {
      type: Function,
      required: true
    },
    onMaxTemp1Change: {
      type: Function,
      required: true
    },
    isDraft: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const columns2 = [
      {
        title: "Step No",
        dataIndex: "step"
      },
      {
        title: "Temperature",
        dataIndex: "pTemp",
        scopedSlots: { customRender: "pTemp" }
      },
      {
        title: "Pressure",
        dataIndex: "pPressure",
        scopedSlots: { customRender: "pPressure" }
      },
      {
        title: "Rotation",
        dataIndex: "rpm",
        scopedSlots: { customRender: "rpm" }
      },
      {
        title: "Time",
        dataIndex: "pTime",
        scopedSlots: { customRender: "pTime" }
      },
      {
        title: "Action",
        align: "center",
        width: 78,
        scopedSlots: { customRender: "action" }
      }
    ];
    const propsObj = {
      pTemp1: "pTemp",
      pPressure1: "pPressure",
      rpm1: "rpm",
      rpm2: "prpM2",
      rpm3: "prpM3",
      rpm4: "prpM4"
    };
    return {
      columns: columns2,
      editingStep: null,
      internalEntity: this.entity,
      propsObj
    };
  },
  computed: {
    tableValues() {
      const {
        pTemp,
        pPressure,
        rpm,
        pTime1,
        pTemp2,
        pPressure2,
        prpM2,
        pTime2,
        pTemp3,
        pPressure3,
        prpM3,
        pTime3,
        pTemp4,
        pPressure4,
        prpM4,
        pTime4
      } = this.internalEntity;
      return [
        {
          step: 1,
          pTemp,
          pPressure,
          rpm,
          pTime: pTime1,
          editable: this.editingStep == 1
        },
        {
          step: 2,
          pTemp: pTemp2,
          pPressure: pPressure2,
          rpm: prpM2,
          pTime: pTime2,
          editable: this.editingStep == 2
        },
        {
          step: 3,
          pTemp: pTemp3,
          pPressure: pPressure3,
          rpm: prpM3,
          pTime: pTime3,
          editable: this.editingStep == 3
        },
        {
          step: 4,
          pTemp: pTemp4,
          pPressure: pPressure4,
          rpm: prpM4,
          pTime: pTime4,
          editable: this.editingStep == 4
        }
      ];
    }
  },
  watch: {
    entity(newVal) {
      this.internalEntity = newVal;
    }
  },
  methods: {
    setEditingStep(stepNo) {
      this.editingStep = stepNo;
      if (stepNo === 0)
        this.save();
    },
    cancelEditingStep(stepNo) {
      this.editingStep = 0;
      const originalEntity = this.crud.getEntity(this.entity.id);
      const updatingProps = [
        this.propsObj[`pTemp${stepNo}`] || `pTemp${stepNo}`,
        this.propsObj[`pPressure${stepNo}`] || `pPressure${stepNo}`,
        this.propsObj[`rpm${stepNo}`] || `rpm${stepNo}`,
        this.propsObj[`pTime${stepNo}`] || `pTime${stepNo}`
      ];
      updatingProps.forEach((prop) => {
        this.internalEntity[prop] = originalEntity[prop];
        this.storeValue(prop, originalEntity[prop]);
      });
    },
    storeInputValue(val, key) {
      const inputKey = this.propsObj[key] || key;
      this.storeValue(inputKey, val);
    },
    onChange(value, resourceKey) {
      this.storeValue(resourceKey, value);
      this.save();
    }
  }
};
const __cssModules$7 = {};
var __component__$7 = /* @__PURE__ */ normalizeComponent(__vue2_script$7, render$7, staticRenderFns$7, false, __vue2_injectStyles$7, "1f8924dc", null, null);
function __vue2_injectStyles$7(context) {
  for (let o in __cssModules$7) {
    this[o] = __cssModules$7[o];
  }
}
var OverPressureRetortProcessTimeValues = /* @__PURE__ */ function() {
  return __component__$7.exports;
}();
var render$6 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_vm._m(0), _c("a-row", { attrs: { "gutter": 16 } }, [_c("a-col", { staticClass: "pb-3", attrs: { "span": 6, "md": 8, "lg": 6 } }, [_c("text-input", { attrs: { "disabled": !_vm.isDraft || !_vm.canEdit, "label": "Process Temp", "value": _vm.entity.pTemp }, on: { "change": function($event) {
    return _vm.onChange($event, "pTemp");
  } } })], 1), _c("a-col", { staticClass: "pb-3", attrs: { "span": 6, "md": 8, "lg": 6 } }, [_c("text-input", { attrs: { "disabled": !_vm.isDraft || !_vm.canEdit, "label": "Pressure", "suffix": "bar", "value": _vm.entity.pPressure }, on: { "change": function($event) {
    return _vm.onChange($event, "pPressure");
  } } })], 1), _c("a-col", { staticClass: "pb-3", attrs: { "span": 6, "md": 8, "lg": 6 } }, [_c("text-input", { attrs: { "disabled": !_vm.isDraft || !_vm.canEdit, "label": "Rotation", "suffix": "RPM", "value": _vm.entity.rpm }, on: { "change": function($event) {
    return _vm.onChange($event, "rpm");
  } } })], 1), _c("a-col", { staticClass: "pb-3", attrs: { "span": 2, "md": 8, "lg": 2 } }, [_c("switch-input", { attrs: { "disabled": !_vm.isDraft || !_vm.canEdit, "label": "Oscillate", "value": _vm.entity.pOscillate }, on: { "change": function($event) {
    return _vm.onChange($event, "pOscillate");
  } } })], 1), _c("a-col", { staticClass: "pb-3 mt-4", attrs: { "span": 4, "md": 16, "lg": 4 } }, [_c("text-input", { attrs: { "disabled": !_vm.isDraft || !_vm.canEdit, "suffix": "RPM", "value": _vm.entity.pOscillateAt }, on: { "change": function($event) {
    return _vm.onChange($event, "pOscillateAt");
  } } })], 1)], 1), _c("a-row", { attrs: { "gutter": 16 } }, [_c("a-col", { staticClass: "pb-3", attrs: { "span": 6, "md": 8, "lg": 6 } }, [_c("div", { staticClass: "text-right mt-4" }, [_vm._v("Inital Temperature")])]), _c("a-col", { staticClass: "pb-3 pt-3", attrs: { "span": 6, "md": 12, "lg": 6 } }, [_c("div", { staticClass: "d-flex w-100" }, [_c("div", { staticClass: "mr-3 w-45" }, [_c("text-input", { attrs: { "disabled": !_vm.isDraft || !_vm.canEdit, "suffix": "\xB0C", "value": _vm.entity.minTemp1 }, on: { "change": _vm.onMinTemp1Change } })], 1), _c("div", { staticClass: "mt-2 w-10" }, [_vm._v("to")]), _c("div", { staticClass: "w-45" }, [_c("text-input", { attrs: { "disabled": !_vm.isDraft || !_vm.canEdit, "suffix": "\xB0C", "value": _vm.entity.maxTemp1 }, on: { "change": _vm.onMaxTemp1Change } })], 1)])]), _c("a-col", { staticClass: "pb-3 mt-3", attrs: { "span": 6, "md": 12, "lg": 6 } }, [_c("div", { staticClass: "d-flex w-100" }, [_c("div", { staticClass: "mr-3 w-45" }, [_c("text-input", { attrs: { "disabled": !_vm.isDraft || !_vm.canEdit, "suffix": "\xB0C", "value": _vm.entity.minTemp2 }, on: { "change": function($event) {
    return _vm.onChange($event, "minTemp2");
  } } })], 1), _c("div", { staticClass: "mt-2 w-10" }, [_vm._v("to")]), _c("div", { staticClass: "w-45" }, [_c("text-input", { attrs: { "disabled": !_vm.isDraft || !_vm.canEdit, "suffix": "\xB0C", "value": _vm.entity.maxTemp2 }, on: { "change": function($event) {
    return _vm.onChange($event, "maxTemp2");
  } } })], 1)])]), _c("a-col", { staticClass: "pb-3 mt-3", attrs: { "span": 6, "md": 12, "lg": 6 } }, [_c("div", { staticClass: "d-flex w-100" }, [_c("div", { staticClass: "mr-3 w-45" }, [_c("text-input", { attrs: { "disabled": !_vm.isDraft || !_vm.canEdit, "suffix": "\xB0C", "value": _vm.entity.minTemp3 }, on: { "change": function($event) {
    return _vm.onChange($event, "minTemp3");
  } } })], 1), _c("div", { staticClass: "mt-2 w-10" }, [_vm._v("to")]), _c("div", { staticClass: "w-45" }, [_c("text-input", { attrs: { "disabled": !_vm.isDraft || !_vm.canEdit, "suffix": "\xB0C", "value": _vm.entity.maxTemp3 }, on: { "change": function($event) {
    return _vm.onChange($event, "maxTemp3");
  } } })], 1)])])], 1), _c("a-row", { attrs: { "gutter": 16 } }, [_c("a-col", { staticClass: "pb-3 pt-3", attrs: { "span": 6 } }, [_c("div", { staticClass: "text-right" }, [_vm._v("Process Time")])]), _c("a-col", { staticClass: "pb-3 pt-2", attrs: { "span": 6 } }, [_c("text-input", { attrs: { "disabled": !_vm.isDraft || !_vm.canEdit, "suffix": "mins", "value": _vm.entity.pTime1 }, on: { "change": function($event) {
    return _vm.onChange($event, "pTime1");
  } } })], 1), _c("a-col", { staticClass: "pb-3 pt-2", attrs: { "span": 6 } }, [_c("text-input", { attrs: { "disabled": !_vm.isDraft || !_vm.canEdit, "suffix": "mins", "value": _vm.entity.pTime2 }, on: { "change": function($event) {
    return _vm.onChange($event, "pTime2");
  } } })], 1), _c("a-col", { staticClass: "pb-3 pt-2", attrs: { "span": 6 } }, [_c("text-input", { attrs: { "disabled": !_vm.isDraft || !_vm.canEdit, "suffix": "mins", "value": _vm.entity.pTime3 }, on: { "change": function($event) {
    return _vm.onChange($event, "pTime3");
  } } })], 1)], 1)], 1);
};
var staticRenderFns$6 = [function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("p", { staticClass: "title" }, [_c("strong", [_vm._v("Process Time Values")])]);
}];
var index_vue_vue_type_style_index_0_scoped_true_lang = "";
const __vue2_script$6 = {
  name: "ProcessTimeValues",
  props: {
    canEdit: {
      type: Boolean,
      required: true
    },
    entity: {
      type: Object,
      required: true
    },
    save: {
      type: Function,
      required: true
    },
    storeValue: {
      type: Function,
      required: true
    },
    onMinTemp1Change: {
      type: Function,
      required: true
    },
    onMaxTemp1Change: {
      type: Function,
      required: true
    },
    isDraft: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onChange(value, resourceKey) {
      this.storeValue(resourceKey, value);
      this.save();
    }
  }
};
const __cssModules$6 = {};
var __component__$6 = /* @__PURE__ */ normalizeComponent(__vue2_script$6, render$6, staticRenderFns$6, false, __vue2_injectStyles$6, "6e1f2840", null, null);
function __vue2_injectStyles$6(context) {
  for (let o in __cssModules$6) {
    this[o] = __cssModules$6[o];
  }
}
var ReceiptRetortProcessTimeValues = /* @__PURE__ */ function() {
  return __component__$6.exports;
}();
var render$5 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "recipe-retort-page" }, [_c("a-card", { staticClass: "mb-4" }, [_c("a-row", { attrs: { "gutter": 16 } }, [_c("a-col", { attrs: { "span": 4, "md": 12, "lg": 4 } }, [_c("text-input", { attrs: { "disabled": !_vm.isDraft || !_vm.canEdit, "label": "Cycle No", "value": _vm.entity.cycleNo }, on: { "change": function($event) {
    return _vm.onChange($event, "cycleNo");
  } } })], 1)], 1), _c("a-row", { staticClass: "pt-3", attrs: { "gutter": 16 } }, [_c("a-col", { staticClass: "pb-3", attrs: { "span": 4, "md": 12, "lg": 4 } }, [_c("text-input", { attrs: { "disabled": !_vm.isDraft || !_vm.canEdit, "label": "Min Process Temp", "suffix": "\xB0C", "value": _vm.entity.minPTemp }, on: { "change": function($event) {
    return _vm.onChange($event, "minPTemp");
  } } })], 1), _c("a-col", { staticClass: "pb-3", attrs: { "span": 4, "md": 12, "lg": 4 } }, [_c("text-input", { attrs: { "disabled": !_vm.isDraft || !_vm.canEdit, "label": "Process Time", "suffix": "mins", "value": _vm.entity.pTimeTxt }, on: { "change": function($event) {
    return _vm.onChange($event, "pTimeTxt");
  } } })], 1), _c("a-col", { staticClass: "pb-3", attrs: { "span": 4, "md": 12, "lg": 4 } }, [_c("text-input", { attrs: { "disabled": !_vm.isDraft || !_vm.canEdit, "label": "Hold Time", "value": _vm.entity.holdTime, "suffix": "mins" }, on: { "change": function($event) {
    return _vm.onChange($event, "holdTime");
  } } })], 1), _c("a-col", { staticClass: "pb-3", attrs: { "span": 4, "md": 12, "lg": 4 } }, [_c("text-input", { attrs: { "disabled": !_vm.isDraft || !_vm.canEdit, "label": "Rotation Speed", "suffix": "RPM", "value": _vm.entity.rpmTxt }, on: { "change": function($event) {
    return _vm.onChange($event, "rpmTxt");
  } } })], 1), _c("a-col", { staticClass: "pb-3", attrs: { "span": 4, "md": 12, "lg": 4 } }, [_c("text-input", { attrs: { "disabled": !_vm.isDraft || !_vm.canEdit, "label": "Minimum Inital Temp", "suffix": "\xB0C", "value": _vm.entity.minTempTxt }, on: { "change": _vm.onMinTempTxtChange } })], 1), _c("a-col", { staticClass: "pb-3", attrs: { "span": 4, "md": 12, "lg": 4 } }, [_c("text-input", { attrs: { "disabled": !_vm.isDraft || !_vm.canEdit, "label": "Full Cycle Time", "suffix": "mins", "value": _vm.entity.fullCycleTime }, on: { "change": function($event) {
    return _vm.onChange($event, "fullCycleTime");
  } } })], 1)], 1)], 1), _c("a-card", { staticClass: "my-4" }, [_c("p", { staticClass: "title mt-10" }, [_c("strong", [_vm._v("Reservoir Control Values")])]), _c("a-row", { attrs: { "gutter": 16 } }, [_c("a-col", { staticClass: "pb-3", attrs: { "span": 8, "md": 24, "lg": 8 } }, [_c("text-input", { staticClass: "mg-right", attrs: { "disabled": !_vm.isDraft || !_vm.canEdit, "label": "Temp Setpoint 90.0 - 140.0\xB0C", "suffix": "\xB0C", "value": _vm.entity.rcTempSetPoint }, on: { "change": function($event) {
    return _vm.onChange($event, "rcTempSetPoint");
  } } })], 1), _c("a-col", { staticClass: "pb-3", attrs: { "span": 8, "md": 24, "lg": 8 } }, [_c("text-input", { staticClass: "mg-right", attrs: { "disabled": !_vm.isDraft || !_vm.canEdit, "label": "Over Pressure Setpoint 0.00 - 5.00 Bar", "suffix": "bar", "value": _vm.entity.rcPressureSetPoint }, on: { "change": function($event) {
    return _vm.onChange($event, "rcPressureSetPoint");
  } } })], 1)], 1), _c("div", { staticClass: "hr-bottom" }), _c("p", { staticClass: "title" }, [_c("strong", [_vm._v("Cycle Values")])]), _c("a-row", { attrs: { "gutter": 16 } }, [_c("a-col", { staticClass: "pb-3", attrs: { "span": 4, "md": 8, "lg": 4 } }, [_c("switch-input", { attrs: { "disabled": !_vm.isDraft || !_vm.canEdit, "label": "Cooling Process Only", "parse": _vm.parseSwitchInputValue, "parse-on-change": _vm.switchValueOnChange, "value": _vm.entity.cvCoolingProcess }, on: { "change": function($event) {
    return _vm.onChange($event, "cvCoolingProcess");
  } } })], 1), _vm.showCVGlassJar ? _c("a-col", { staticClass: "pb-3", attrs: { "span": 4, "md": 8, "lg": 4 } }, [_c("switch-input", { attrs: { "disabled": !_vm.isDraft || !_vm.canEdit, "label": "Glass Jar", "parse": _vm.parseSwitchInputValue, "parse-on-change": _vm.switchValueOnChange, "value": _vm.entity.cvGlassJar }, on: { "change": function($event) {
    return _vm.onChange($event, "cvGlassJar");
  } } })], 1) : _vm._e(), _c("a-col", { staticClass: "pb-3", attrs: { "span": 4, "md": 16, "lg": 4 } }, [_c("text-input", { staticClass: "mg-right", attrs: { "disabled": !_vm.isDraft || !_vm.canEdit, "label": "Water Level (-100% (20%))", "value": _vm.entity.cvWaterLevel }, on: { "change": function($event) {
    return _vm.onChange($event, "cvWaterLevel");
  } } })], 1)], 1), _c("div", { staticClass: "hr-bottom" }), _c("div", [_c("p", { staticClass: "title" }, [_c("strong", [_vm._v("Come Up Time Values")])]), _c("a-table", { staticClass: "mb-3", attrs: { "bordered": "", "columns": _vm.columns, "data-source": _vm.comeUpTimeValues, "pagination": false, "row-key": "step" }, scopedSlots: _vm._u([{ key: "cuTemp", fn: function(text, record) {
    return [record.editable ? _c("div", [_c("text-input", { attrs: { "value": text }, on: { "change": function($event) {
      return _vm.storeValue("cuTemp" + record.step, $event);
    } } })], 1) : _c("div", [_vm._v(_vm._s(text || "-"))])];
  } }, { key: "cuPressure", fn: function(text, record) {
    return [record.editable ? _c("div", [_c("text-input", { attrs: { "value": text }, on: { "change": function($event) {
      return _vm.storeValue("cuPressure" + record.step, $event);
    } } })], 1) : _c("div", [_vm._v(_vm._s(text || "-"))])];
  } }, { key: "curpM", fn: function(text, record) {
    return [record.editable ? _c("div", [_c("text-input", { attrs: { "value": text }, on: { "change": function($event) {
      return _vm.storeValue("curpM" + record.step, $event);
    } } })], 1) : _c("div", [_vm._v(_vm._s(text || "-"))])];
  } }, { key: "cuOscillateAt", fn: function(text, record) {
    return [record.editable ? _c("div", [_c("text-input", { attrs: { "value": text }, on: { "change": function($event) {
      return _vm.storeValue("cuOscillateAt" + record.step, $event);
    } } })], 1) : _c("div", [_vm._v(_vm._s(text || "-"))])];
  } }, { key: "cuTime", fn: function(text, record) {
    return [record.editable ? _c("div", [_c("text-input", { attrs: { "value": text }, on: { "change": function($event) {
      return _vm.storeValue("cuTime" + record.step, $event);
    } } })], 1) : _c("div", [_vm._v(_vm._s(text || "-"))])];
  } }, { key: "action", fn: function(record) {
    return [_vm.isDraft && _vm.canEdit ? _c("div", [record.editable ? _c("div", [_c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Save")];
    }, proxy: true }], null, true) }, [_c("a-button", { staticClass: "mr-2", attrs: { "type": "primary", "icon": "check", "size": "small" }, on: { "click": _vm.onSaveStep } })], 1), _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Cancel")];
    }, proxy: true }], null, true) }, [_c("a-button", { attrs: { "icon": "close", "size": "small" }, on: { "click": function($event) {
      return _vm.onCancelEditStepClick(record.step);
    } } })], 1)], 1) : _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Edit")];
    }, proxy: true }], null, true) }, [_c("a-button", { attrs: { "icon": "edit", "size": "small", "type": "link" }, on: { "click": function($event) {
      return _vm.onEditStepClick(record.step);
    } } })], 1)], 1) : _vm._e()];
  } }]) }), _c("a-row", { attrs: { "gutter": 16 } }, [_c("a-col", { staticClass: "pb-3", attrs: { "span": 4, "md": 10, "lg": 4 } }, [_c("text-input", { attrs: { "disabled": !_vm.isDraft || !_vm.canEdit, "label": "No. of Come Up Steps", "value": _vm.entity.noCuSteps }, on: { "change": function($event) {
    return _vm.onChange($event, "noCuSteps");
  } } })], 1)], 1)], 1), _c("div", { staticClass: "hr-bottom" }), _vm.entity.id !== void 0 ? _c(_vm.getComponent(), { tag: "component", attrs: { "can-edit": _vm.canEdit, "crud": _vm.crud, "entity": _vm.entity, "is-draft": _vm.isDraft, "on-max-temp1-change": _vm.onMaxTemp1Change, "on-min-temp1-change": _vm.onMinTemp1Change, "save": _vm.save, "store-value": _vm.storeValue } }) : _vm._e(), _c("div", { staticClass: "hr-bottom" }), _c("div", [_c("p", { staticClass: "title" }, [_c("strong", [_vm._v("Cooling Time Value")])]), _c("a-row", { attrs: { "gutter": 16 } }, [_c("a-col", { attrs: { "span": 6 } }, [_c("text-input", { attrs: { "disabled": !_vm.isDraft || !_vm.canEdit, "label": "Total Cooling Time", "value": _vm.entity.cTime, "suffix": "mins" }, on: { "change": function($event) {
    return _vm.onChange($event, "cTime");
  } } })], 1), _c("a-col", { attrs: { "span": 6 } }, [_c("text-input", { attrs: { "disabled": !_vm.isDraft || !_vm.canEdit, "label": "Rotation", "value": _vm.entity.cprm, "suffix": "RPM" }, on: { "change": function($event) {
    return _vm.onChange($event, "cprm");
  } } })], 1), _c("a-col", { attrs: { "span": 2 } }, [_c("switch-input", { attrs: { "disabled": !_vm.isDraft || !_vm.canEdit, "label": "Oscillate", "value": _vm.entity.cOscillate }, on: { "change": function($event) {
    return _vm.onChange($event, "cOscillate");
  } } })], 1), _c("a-col", { staticClass: "mt", attrs: { "span": 4 } }, [_c("text-input", { attrs: { "disabled": !_vm.isDraft || !_vm.canEdit, "suffix": "degrees", "value": _vm.entity.cOscillateAt }, on: { "change": function($event) {
    return _vm.onChange($event, "cOscillateAt");
  } } })], 1), _c("a-col", { attrs: { "span": 6 } }, [_c("text-input", { attrs: { "disabled": !_vm.isDraft || !_vm.canEdit, "label": "Hot Water Remaining Level (0 - 100%)", "value": _vm.entity.cvHotWaterLevelRemain, "suffix": "%" }, on: { "change": function($event) {
    return _vm.onChange($event, "cvHotWaterLevelRemain");
  } } })], 1)], 1), _c("a-row", { staticClass: "pt-3", attrs: { "gutter": 16 } }, [_c("a-col", { attrs: { "span": 6 } }, [_c("text-input", { attrs: { "disabled": !_vm.isDraft || !_vm.canEdit, "label": "Pressure Cooling Time", "value": _vm.entity.pcTime, "suffix": "mins" }, on: { "change": function($event) {
    return _vm.onChange($event, "pcTime");
  } } })], 1), _c("a-col", { attrs: { "span": 6 } }, [_c("text-input", { attrs: { "disabled": !_vm.isDraft || !_vm.canEdit, "label": "Pressure", "value": _vm.entity.cPressure, "suffix": "bar" }, on: { "change": function($event) {
    return _vm.onChange($event, "cPressure");
  } } })], 1), _c("a-col", { attrs: { "span": 6 } }, [_c("text-input", { attrs: { "disabled": !_vm.isDraft || !_vm.canEdit, "label": "Final Temp", "value": _vm.entity.pcFinalTemp, "suffix": "\xB0C" }, on: { "change": function($event) {
    return _vm.onChange($event, "pcFinalTemp");
  } } })], 1)], 1)], 1)], 1)], 1);
};
var staticRenderFns$5 = [];
var ViewRecipeRetort_vue_vue_type_style_index_0_scoped_true_lang = "";
const columns = [
  {
    title: "Step No",
    dataIndex: "step",
    className: "align-center"
  },
  {
    title: "Temperature",
    dataIndex: "cuTemp",
    scopedSlots: { customRender: "cuTemp" }
  },
  {
    title: "Pressure",
    dataIndex: "cuPressure",
    scopedSlots: { customRender: "cuPressure" }
  },
  {
    title: "Rotation",
    dataIndex: "curpM",
    scopedSlots: { customRender: "curpM" }
  },
  {
    title: "Oscillate",
    dataIndex: "cuOscillateAt",
    scopedSlots: { customRender: "cuOscillateAt" }
  },
  {
    title: "Time",
    dataIndex: "cuTime",
    scopedSlots: { customRender: "cuTime" }
  },
  {
    title: "Action",
    align: "center",
    width: 78,
    scopedSlots: { customRender: "action" }
  }
];
const __vue2_script$5 = {
  name: "ViewRecipeRetort",
  components: {
    ReceiptRetortProcessTimeValues,
    OverPressureRetortProcessTimeValues
  },
  inject: ["crud", "redirectRoute", "resourceName"],
  props: {
    canEdit: {
      type: Boolean,
      required: true
    },
    methodId: {
      type: Number,
      required: true
    },
    recipeRetortApiUrl: {
      type: String,
      required: true
    },
    sterilizationData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      columns,
      entity: {},
      editingKey: "",
      editingStep: 0
    };
  },
  computed: {
    actionType() {
      return this.entity.id ? "replace" : "create";
    },
    isDraft() {
      return this.sterilizationData.isDraft;
    },
    showCVGlassJar() {
      return this.methodId == STERILIZATION_METHODS.OVERPRESSURE_RETORT;
    },
    comeUpTimeValues() {
      const {
        cuTemp1,
        cuTemp2,
        cuPressure1,
        cuPressure2,
        curpM1,
        curpM2,
        cuOscillateAt1,
        cuOscillateAt2,
        cuTime1,
        cuTime2
      } = this.entity;
      return [
        {
          step: 1,
          cuTemp: cuTemp1,
          cuPressure: cuPressure1,
          curpM: curpM1,
          cuOscillateAt: cuOscillateAt1,
          cuTime: cuTime1,
          editable: this.editingStep == 1
        },
        {
          step: 2,
          cuTemp: cuTemp2,
          cuPressure: cuPressure2,
          curpM: curpM2,
          cuOscillateAt: cuOscillateAt2,
          cuTime: cuTime2,
          editable: this.editingStep == 2
        }
      ];
    },
    formType() {
      return this.entity.id ? "editForm" : "createForm";
    }
  },
  watch: {
    recipeRetortApiUrl(newVal) {
      this.fetchEntity(newVal);
    }
  },
  async mounted() {
    this.fetchEntity(this.recipeRetortApiUrl);
  },
  methods: {
    async fetchEntity(recipeRetortApiUrl) {
      const { data } = await this.crud.fetchEntity(recipeRetortApiUrl);
      this.storeValues(data);
    },
    getComponent() {
      if (this.methodId === STERILIZATION_METHODS.OVERPRESSURE_RETORT)
        return OverPressureRetortProcessTimeValues;
      return ReceiptRetortProcessTimeValues;
    },
    parseSwitchInputValue(val) {
      return val != 0 && val != null ? true : false;
    },
    save: _.debounce(async function() {
      if (this.entity.id == 0) {
        this.crud.updateEntity(this.formType, {
          resourceKey: "id",
          value: void 0
        });
      }
      this.crud.submitEntity(this.actionType, this.recipeRetortApiUrl).then(({ data }) => this.storeValues(data));
      this.$emit("change", false);
    }, 1e3),
    storeValue(resourceKey, value) {
      this.entity = __spreadProps(__spreadValues({}, this.entity), {
        [resourceKey]: value
      });
      this.crud.updateEntity(this.formType, { resourceKey, value });
    },
    storeValues(data) {
      const fields = Object.keys(data);
      fields.forEach((key) => this.storeValue(key, data[key]));
    },
    switchValueOnChange(val) {
      return val ? -1 : 0;
    },
    onChange(value, resourceKey) {
      this.storeValue(resourceKey, value);
      this.save();
    },
    onMinTempTxtChange(value) {
      let minTemp = !isNaN(value) ? value : value.endsWith("\xB0C") && !isNaN(value.substr(0, value.length - 2)) ? value.substr(0, value.length - 2) : this.entity.minTemp1;
      this.storeValues({
        minTempTxt: value,
        minTemp1: minTemp,
        minTemp2: minTemp,
        minTemp3: minTemp
      });
      this.save();
    },
    onMinTemp1Change(value) {
      this.storeValues({
        minTemp1: value,
        minTemp2: value,
        minTemp3: value
      });
      this.save();
    },
    onMaxTemp1Change(value) {
      this.storeValues({
        maxTemp1: value,
        maxTemp2: value,
        maxTemp3: value
      });
      this.save();
    },
    onCancelEditStepClick(step) {
      this.editingStep = 0;
      const originalEntity = this.crud.getEntity(this.entity.id);
      const updatingProps = [
        `cuTemp${step}`,
        `cuPressure${step}`,
        `curpM${step}`,
        `cuOscillateAt${step}`,
        `cuTime${step}`
      ];
      updatingProps.forEach((prop) => {
        this.entity[prop] = originalEntity[prop];
        this.storeValue(this.entity[prop], prop);
      });
      this.$emit("change", false);
    },
    onEditStepClick(step) {
      this.$emit("change", true);
      this.editingStep = step;
    },
    onSaveStep() {
      this.editingStep = 0;
      this.save();
    }
  }
};
const __cssModules$5 = {};
var __component__$5 = /* @__PURE__ */ normalizeComponent(__vue2_script$5, render$5, staticRenderFns$5, false, __vue2_injectStyles$5, "37b220e4", null, null);
function __vue2_injectStyles$5(context) {
  for (let o in __cssModules$5) {
    this[o] = __cssModules$5[o];
  }
}
var ViewRecipeRetort = /* @__PURE__ */ function() {
  return __component__$5.exports;
}();
var render$4 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "view-recipe-retort p-5" }, [_c("resource", { attrs: { "name": "sterilization.common.stacking-patterns", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "sterilization.data-entries.retorts", "api-url": _vm.apiUrl, "redirect-route": "/sterilization-specifications/data-entries" } }, [_c("view-recipe-retort", { attrs: { "can-edit": _vm.canEdit, "method-id": _vm.methodId, "recipe-retort-api-url": _vm.recipeRetortApiUrl, "sterilization-data": _vm.sterilizationData }, on: { "change": _vm.onChange } })], 1)], 1);
};
var staticRenderFns$4 = [];
const apiUrl$3 = "#{VUE_APP_API_URL}#";
const __vue2_script$4 = {
  components: {
    ViewRecipeRetort
  },
  props: {
    canEdit: {
      type: Boolean,
      required: true
    },
    dataEntryId: {
      type: Number,
      required: true
    },
    methodId: {
      type: Number,
      required: true
    },
    sterilizationData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      apiUrl: apiUrl$3
    };
  },
  computed: {
    recipeRetortApiUrl() {
      return `${this.apiUrl}/sterilization/data-entries/${this.dataEntryId}/retorts`;
    }
  },
  methods: {
    onChange(e) {
      this.$emit("change", e);
    }
  }
};
const __cssModules$4 = {};
var __component__$4 = /* @__PURE__ */ normalizeComponent(__vue2_script$4, render$4, staticRenderFns$4, false, __vue2_injectStyles$4, null, null, null);
function __vue2_injectStyles$4(context) {
  for (let o in __cssModules$4) {
    this[o] = __cssModules$4[o];
  }
}
var ViewCommonRetort = /* @__PURE__ */ function() {
  return __component__$4.exports;
}();
const Filters = {
  filters: {
    toFixed(value, digit) {
      const _value = parseFloat(value);
      if (Number.isNaN(_value)) {
        return;
      }
      return _value.toFixed(digit);
    }
  }
};
var render$3 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "kingstreet-steriflow m-5" }, [_c("a-card", { staticClass: "mb-4", attrs: { "bordered": false } }, [_vm.entity ? _c("div", { class: _vm.isTabletScreen ? "d-flex flex-column justify-content-start" : "d-flex align-items-end" }, [_c("div", { staticClass: "d-flex flex-column text-container", class: _vm.isTabletScreen ? "" : "pr-4" }, [_c("text-input", { attrs: { "disabled": !_vm.isDraft || !_vm.canEdit, "label": "Cycle Number", "value": _vm.entity.cycleNo }, on: { "change": function(e) {
    return _vm.formChange(e, "cycleNo");
  } } }), _c("text-input", { staticClass: "pt-3", attrs: { "disabled": !_vm.isDraft || !_vm.canEdit, "label": "Min Process Temp", "value": _vm.entity.minPTemp, "suffix": "\xB0C" }, on: { "change": function(e) {
    return _vm.formChange(e, "minPTemp");
  } } })], 1), _c("text-input", { class: _vm.isTabletScreen ? "pt-3" : "pr-4", attrs: { "disabled": !_vm.isDraft || !_vm.canEdit, "label": "Process Time", "value": _vm.entity.pTime, "suffix": "mins" }, on: { "change": function(e) {
    return _vm.formChange(e, "pTime");
  } } }), _c("text-input", { class: _vm.isTabletScreen ? "pt-3" : "pr-4", attrs: { "disabled": !_vm.isDraft || !_vm.canEdit, "label": "Rotation Speed", "value": _vm.entity.rpm, "suffix": "RPM" }, on: { "change": function(e) {
    return _vm.formChange(e, "rpm");
  } } }), _vm.methodId != _vm.STERILIZATION_METHODS.LAGARDE_RETORTS ? _c("switch-input", { class: _vm.isTabletScreen ? "switch-input pt-3" : "switch-input pr-4", attrs: { "disabled": !_vm.isDraft || !_vm.canEdit, "label": "Oscillate", "parse": _vm.parseOscillateValue, "parse-on-change": _vm.parseOscillateValueOnChange, "value": _vm.entity.isOscillate }, on: { "change": function(e) {
    return _vm.formChange(e, "isOscillate");
  } } }) : _vm._e(), _c("text-input", { class: _vm.isTabletScreen ? "pt-3" : "", attrs: { "disabled": !_vm.isDraft || !_vm.canEdit, "label": "Minimum Initial Temp", "value": _vm.entity.minTemp, "suffix": "\xB0C" }, on: { "change": function(e) {
    return _vm.formChange(e, "minTemp");
  } } })], 1) : _vm._e()]), _c("div", { staticClass: "d-flex justify-content-between mb-3" }, [_c("a-select", { staticClass: "filter-select", attrs: { "value": _vm.valueFilter, "disabled": _vm.editingKey === 0 || _vm.editingKey !== "" }, on: { "change": _vm.onChangeFilter } }, _vm._l(_vm.options, function(option) {
    return _c("a-select-option", { key: option.value, attrs: { "value": option.value } }, [_c("a-icon", { attrs: { "type": option.iconType, "theme": option.iconTheme } }), _vm._v(" " + _vm._s(option.text) + " ")], 1);
  }), 1), _vm.isDraft && _vm.canEdit ? _c("a-button", { attrs: { "type": "primary", "disabled": _vm.entity && (_vm.entity.id === 0 || _vm.editingKey === 0 || _vm.editingKey !== "") }, on: { "click": _vm.addStage } }, [_vm._v("Add Stage")]) : _vm._e()], 1), _c("a-table", { staticClass: "mb-4", attrs: { "columns": _vm.columns, "data-source": _vm.dataFilter, "row-key": "id", "custom-row": _vm.customRow, "scroll": { x: _vm.scrollX }, "pagination": false, "bordered": "" }, scopedSlots: _vm._u([_vm._l([
    "id",
    "stage",
    "function",
    "duration",
    "temperature",
    "pressure",
    "rotation"
  ], function(col) {
    return { key: col, fn: function(text, record) {
      return [_c("div", { key: col, class: [{ "ant-table-col__stage": col == "stage" }] }, [record.editable && col != "function" && col != "stage" ? _c("a-input", { staticStyle: { "margin": "-5px 0" }, attrs: { "value": text }, on: { "change": function(e) {
        return _vm.handleChange(e.target.value, record.id, col);
      } } }) : record.editable && col == "function" && col != "stage" ? _c("select-input", { key: "functionsId", attrs: { "reference": "sterilization.common.functions", "source": "id", "source-label": "name", "value": record.functionId, "span": 24 }, on: { "change": function(e) {
        return _vm.handleChange(e, record.id, col);
      } } }) : [col == "pressure" ? _c("span", [_vm._v(_vm._s(_vm._f("toFixed")(text, 2)))]) : col == "temperature" ? _c("span", [_vm._v(_vm._s(_vm._f("toFixed")(text, 1)))]) : _c("span", [_vm._v(_vm._s(text))]), _vm.isDraft && _vm.canEdit && _vm.dataFilter.length > 1 && col == "stage" && _vm.editingKey === "" ? _c("div", { staticClass: "d-flex justify-content-between" }, [_vm.isOrdering & record.id === _vm.steriflowDetailSelected ? _c("div", { staticClass: "ordering" }, [_c("a-icon", { attrs: { "type": "loading" } })], 1) : _c("a-button-group", { staticClass: "d-flex" }, [_c("a-button", { attrs: { "disabled": _vm.getSteriflowDetailIndex(record.stage) === 0 || _vm.isOrdering }, on: { "click": function($event) {
        return _vm.onOrdering(record.id, "up");
      } } }, [_c("a-icon", { attrs: { "type": "up" } })], 1), _c("a-button", { attrs: { "disabled": _vm.getSteriflowDetailIndex(record.stage) === _vm.dataFilter.length - 1 || _vm.isOrdering }, on: { "click": function($event) {
        return _vm.onOrdering(record.id, "down");
      } } }, [_c("a-icon", { attrs: { "type": "down" } })], 1)], 1)], 1) : _vm._e()]], 2)];
    } };
  }), { key: "action", fn: function(text, record) {
    return [_vm.isDraft && _vm.canEdit ? _c("div", { staticClass: "editable-row-action" }, [record.editable ? [_c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Save")];
    }, proxy: true }], null, true) }, [_c("a-button", { attrs: { "icon": "check", "size": "small", "type": "primary" }, on: { "click": function($event) {
      return _vm.saveItem(record.id);
    } } })], 1), _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Cancel")];
    }, proxy: true }], null, true) }, [_c("a-button", { attrs: { "icon": "close", "size": "small", "type": "button" }, on: { "click": function($event) {
      return _vm.cancelItem(record.id);
    } } })], 1)] : [_c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Edit")];
    }, proxy: true }], null, true) }, [_c("a-button", { attrs: { "disabled": _vm.editingKey != "", "icon": "edit", "size": "small", "type": "link" }, on: { "click": function($event) {
      return _vm.editItem(record.id);
    } } })], 1), _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v(_vm._s(record.isInactive ? "Activate" : "Deactivate"))];
    }, proxy: true }], null, true) }, [_c("a-button", { staticClass: "list__action-toggle-active", attrs: { "disabled": _vm.editingKey != "", "icon": record.isInactive ? "eye-invisible" : "eye", "size": "small", "type": "link" }, on: { "click": function($event) {
      return _vm.onToggleActiveClick(record);
    } } })], 1)]], 2) : _vm._e()];
  } }], null, true) })], 1);
};
var staticRenderFns$3 = [];
var ViewsKingstreetSteriflow_vue_vue_type_style_index_0_scoped_true_lang = "";
const apiUrl$2 = "#{VUE_APP_API_URL}#";
const tempText = " \xBAC";
const __vue2_script$3 = {
  name: "ViewsKingstreetSteriflow",
  inject: ["resourceProps"],
  mixins: [Filters],
  props: {
    canEdit: {
      type: Boolean,
      required: true
    },
    methodId: {
      type: Number,
      required: true
    },
    sterilizationData: {
      type: Object,
      required: true
    }
  },
  data() {
    const [steriflowProps, steriflowDetailProps] = this.resourceProps;
    return {
      STERILIZATION_METHODS,
      columns: [],
      cacheData: this.data,
      cacheFormData: {},
      data: [],
      dataActive: [],
      dataFilter: [],
      dataInactive: [],
      editingKey: "",
      entity: null,
      isLoading: false,
      isTabletScreen: false,
      minTempVal: "",
      scrollX: 0,
      steriflowDetailProps,
      steriflowProps,
      valueFilter: "active",
      options: [
        {
          text: "All",
          value: "all",
          iconType: "container",
          iconTheme: "twoTone"
        },
        {
          text: "Active",
          value: "active",
          iconType: "eye",
          iconTheme: "twoTone"
        },
        {
          text: "Inactive",
          value: "inactive",
          iconType: "eye-invisible",
          iconTheme: "outlined"
        }
      ],
      isOrdering: false,
      steriflowDetailSelected: null
    };
  },
  computed: {
    isDraft() {
      return this.sterilizationData.isDraft;
    },
    customUrl() {
      return `${apiUrl$2}/sterilization/data-entries/${this.$route.params.id}`;
    }
  },
  watch: {
    isDraft(newVal) {
      if (newVal)
        return;
      this.saveItem(this.editingKey);
    },
    sterilizationData(newVal) {
      this.sterilizationData = newVal;
      this.fetchSteriflows();
    }
  },
  mounted() {
    this.isLoading = true;
    this.isTabletScreen = window.matchMedia("(max-width: 1024px)").matches ? true : false;
    this.scrollX = window.matchMedia("(max-width: 1024px)").matches ? 1e3 : 0;
    this.fetchSteriflows();
    this.minTempVal = this.entity && this.entity.minTemp ? this.entity.minTemp.replace(" \xB0C", "") : "";
    this.isLoading = false;
  },
  methods: {
    fetchSteriflows() {
      this.steriflowProps.crud.fetchEntity(`${this.customUrl}/steriflows`).then((response) => {
        this.entity = response.data;
        this.formData = this.entity;
        this.cacheFormData = Object.assign({}, this.entity);
        this.columns = [
          {
            title: "Stage",
            dataIndex: "stage",
            width: 125,
            fixed: this.isTabletScreen ? "left" : false,
            scopedSlots: { customRender: "stage" }
          },
          {
            title: "Function",
            dataIndex: "function",
            width: "40%",
            scopedSlots: { customRender: "function" }
          },
          {
            title: "Duration (min)",
            dataIndex: "duration",
            width: 160,
            scopedSlots: { customRender: "duration" }
          },
          {
            title: "Temperature",
            dataIndex: "temperature",
            width: 160,
            scopedSlots: { customRender: "temperature" }
          },
          {
            title: "Pressure",
            dataIndex: "pressure",
            align: "left",
            width: 160,
            scopedSlots: { customRender: "pressure" }
          },
          {
            title: "Rotation",
            dataIndex: "rotation",
            width: 160,
            scopedSlots: { customRender: "rotation" }
          },
          {
            title: "Action",
            dataIndex: "action",
            width: 78,
            align: "center",
            fixed: this.isTabletScreen ? "right" : false,
            scopedSlots: { customRender: "action" }
          }
        ];
        if (this.methodId === 12)
          this.columns = this.columns.filter((val) => val.dataIndex !== "rotation");
        this.data = this.entity.details ? this.entity.details : [];
        this.cacheData = this.data.map((item) => __spreadValues({}, item));
        this.updateDataFilter();
      });
    },
    parseOscillateValue(val) {
      return val != 0 ? true : false;
    },
    parseOscillateValueOnChange(val) {
      return val ? -1 : 0;
    },
    updateDataFilter() {
      this.dataActive = this.data.filter((item) => item.isInactive === false);
      this.dataInactive = this.data.filter((item) => item.isInactive === true);
      this.onChangeFilter(this.valueFilter);
    },
    handleChange: _.debounce(function(value, id, column) {
      column = column === "function" ? "functionId" : column;
      if (value !== "") {
        let isNumber = /^\d+(\.\d+)?$/.test(value);
        if (!isNumber) {
          value = this.cacheData.filter((item) => id === item.id)[0][column];
        } else
          value = parseFloat(value);
      }
      const newData = [...this.data];
      const target = newData.filter((item) => id === item.id)[0];
      if (target) {
        target[column] = value;
        this.data = [...newData];
      }
    }, 400),
    storeValue(value, resourceKey, resourceProps) {
      if (!resourceKey.includes(",")) {
        this[resourceProps].crud.updateEntity("editForm", {
          resourceKey,
          value
        });
        return;
      }
      const resourceKeys = resourceKey.split(",");
      const type = value.id !== 0 ? "editForm" : "createForm";
      resourceKeys.forEach((key) => this[resourceProps].crud.updateEntity(type, {
        resourceKey: key,
        value: value[key]
      }));
    },
    editItem(id) {
      const newData = [...this.data];
      const target = newData.filter((item) => id === item.id)[0];
      this.editingKey = id;
      if (target) {
        target.editable = true;
        this.data = newData;
      }
      this.$emit("change", true);
    },
    saveItem(id) {
      const newData = [...this.data];
      const newCacheData = [...this.cacheData];
      const target = newData.filter((item) => id === item.id)[0];
      const targetCache = newCacheData.filter((item) => id === item.id)[0];
      if (target) {
        if (id === 0) {
          this.storeValue(target, "stage,functionId,duration,temperature,pressure,rotation", "steriflowDetailProps");
          this.steriflowDetailProps.crud.submitEntity("create", `${this.customUrl}/steriflow-details`).then((response) => {
            delete target.editable;
            Object.assign(target, response.data);
            this.data = newData;
            this.cacheData = this.data.map((item) => __spreadValues({}, item));
            this.editingKey = "";
            this.fetchSteriflows();
            this.$emit("change", false);
          }).catch(() => {
            this.data = this.cacheData;
            this.$emit("change", false);
          });
        } else {
          this.storeValue(target, "stage,functionId,duration,temperature,pressure,rotation,isInactive", "steriflowDetailProps");
          this.steriflowDetailProps.crud.submitEntity("replace", `${this.customUrl}/steriflow-details/${target.id}`).then((response) => {
            delete target.editable;
            Object.assign(target, response.data);
            this.data = newData;
            Object.assign(targetCache, target);
            this.cacheData = newCacheData;
            this.editingKey = "";
            this.fetchSteriflows();
            this.$emit("change", false);
          }).catch(() => {
            this.data = this.cacheData;
            this.$emit("change", false);
          });
        }
      }
    },
    cancelItem(id) {
      if (id === 0) {
        this.data.pop();
        this.updateDataFilter();
        this.editingKey = "";
      } else {
        const newData = [...this.data];
        const target = newData.filter((item) => id === item.id)[0];
        this.editingKey = "";
        if (target) {
          Object.assign(target, this.cacheData.filter((item) => id === item.id)[0]);
          delete target.editable;
          this.data = newData;
        }
      }
      this.$emit("change", false);
    },
    toggleActive(record) {
      return () => {
        let valInactive = !record.isInactive;
        this.steriflowProps.crud.submitPartialEntity({
          resourceName: this.steriflowProps.resourceName,
          id: record.id,
          customUrl: this.customUrl ? `${this.customUrl}/steriflow-details/${record.id}` : null,
          data: [
            {
              op: "replace",
              path: "/IsInactive",
              value: valInactive
            }
          ]
        }).then(() => {
          this.data.forEach((m) => m.isInactive = m.id === record.id ? valInactive : m.isInactive);
          this.updateDataFilter();
          this.cacheData = this.data;
        });
      };
    },
    onToggleActiveClick(record) {
      let icon, okType, title;
      if (record.isInactive) {
        title = "Do you want to Activate this item?";
        icon = "eye";
        okType = "primary";
      } else {
        title = "Do you want to Deactivate this item?";
        icon = "eye-invisible";
        okType = "danger";
      }
      this.$confirm({
        class: "list__modal-confirm",
        icon,
        title,
        cancelText: "No",
        okText: "Yes",
        okType,
        onOk: this.toggleActive(record)
      });
    },
    formChange(value, key) {
      if (key === "isOscillate")
        value = value !== 0 ? true : false;
      if (key === "minPTemp" || key === "minTemp" || key === "pTime") {
        this.entity[key + "Text"] = key.includes("Temp") ? value + tempText : value + " Minutes";
      }
      if (key === "rpm") {
        this.entity[key + "Text"] = value + " RPM";
      }
      this.entity[key] = value;
      this.submitForm();
    },
    submitForm: _.debounce(function() {
      this.storeValue(this.entity, "cycleNo,minPTemp,minPTempText,pTime,pTimeText,rpm,rpmText,minTemp,minTempText,isOscillate", "steriflowProps");
      const type = this.entity.id === 0 ? "create" : "replace";
      this.steriflowProps.crud.submitEntity(type, `${this.customUrl}/steriflows`).then((response) => {
        Object.assign(this.entity, response.data);
        Object.assign(this.cacheFormData, response.data);
      });
    }, 1e3),
    addStage() {
      let objCreate = {
        id: 0,
        stage: this.data.length + 1,
        functionId: 2,
        function: "Coming Up",
        duration: 0,
        temperature: 0,
        pressure: 0,
        rotation: 0,
        isInactive: this.valueFilter !== "inactive" ? false : true
      };
      this.data.push(objCreate);
      this.updateDataFilter();
      this.editItem(0);
      this.editingKey = objCreate.id;
      let content = document.querySelector(".view-kingstreet-steriflow");
      let scrollHeight = content.offsetHeight + 71;
      content.style.height = scrollHeight + "px";
      let body = document.querySelector("#kh-master-layout > .ant-layout > .ant-layout-content");
      body.scrollTop = body.scrollHeight;
      this.$emit("change", true);
    },
    onChangeFilter(value) {
      this.valueFilter = value;
      switch (value) {
        case "all":
          this.dataFilter = this.data;
          break;
        case "active":
          this.dataFilter = this.dataActive;
          break;
        case "inactive":
          this.dataFilter = this.dataInactive;
          break;
        default:
          this.dataFilter = this.data;
      }
      this.sortSteriflowDetails();
    },
    customRow(record) {
      if (record.isInactive) {
        return { class: "list__item--inactive" };
      }
      return {};
    },
    onOrdering(steriflowDetailId, direction) {
      this.setOrderingStatus(steriflowDetailId);
      const steriflowDetailIndex = this.dataFilter.findIndex((detail) => detail.id === steriflowDetailId);
      const steriflowDetail = this.dataFilter.find((detail) => detail.id === steriflowDetailId);
      const steriflowDetailAffected = this.dataFilter[direction === "up" ? steriflowDetailIndex - 1 : steriflowDetailIndex + 1];
      Promise.all([
        this.getOrderingPromise(steriflowDetail.id, steriflowDetailAffected.stage),
        this.getOrderingPromise(steriflowDetailAffected.id, steriflowDetail.stage)
      ]).then(([steriflowDetailRes, steriflowDetailAffectedRes]) => {
        const newSteriflowDetail = steriflowDetailRes.data;
        const newSteriflowDetailAffected = steriflowDetailAffectedRes.data;
        Object.assign(steriflowDetail, newSteriflowDetail);
        Object.assign(steriflowDetailAffected, newSteriflowDetailAffected);
      }).finally(() => {
        this.sortSteriflowDetails();
        this.setOrderingStatus();
      });
    },
    setOrderingStatus(steriflowDetailId = null) {
      this.steriflowDetailSelected = steriflowDetailId;
      this.isOrdering = steriflowDetailId !== null;
    },
    getOrderingPromise(steriflowDetailId, stage) {
      return this.steriflowDetailProps.crud.submitPartialEntity({
        resourceName: this.steriflowDetailProps.resourceName,
        id: steriflowDetailId,
        data: [
          {
            op: "replace",
            path: "/Stage",
            value: stage
          }
        ],
        customUrl: `${this.customUrl}/steriflow-details/${steriflowDetailId}`
      });
    },
    sortSteriflowDetails() {
      this.dataFilter = this.dataFilter.sort((steriflowDetail1, steriflowDetail2) => steriflowDetail1.stage - steriflowDetail2.stage);
    },
    getSteriflowDetailIndex(steriflowDetailStage) {
      return this.dataFilter.findIndex((detail) => detail.stage === steriflowDetailStage);
    }
  }
};
const __cssModules$3 = {};
var __component__$3 = /* @__PURE__ */ normalizeComponent(__vue2_script$3, render$3, staticRenderFns$3, false, __vue2_injectStyles$3, "b5e23bd8", null, null);
function __vue2_injectStyles$3(context) {
  for (let o in __cssModules$3) {
    this[o] = __cssModules$3[o];
  }
}
var ViewsKingstreetSteriflow$1 = /* @__PURE__ */ function() {
  return __component__$3.exports;
}();
var render$2 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "view-kingstreet-steriflow" }, [_c("resource", { attrs: { "name": "sterilization.common.functions", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": ["sterilization.data-entries.steriflows", "sterilization.data-entries.steriflow-details"], "api-url": _vm.apiUrl } }, [_c("views-kingstreet-steriflow", { attrs: { "can-edit": _vm.canEdit, "method-id": _vm.methodId, "sterilization-data": _vm.sterilizationData }, on: { "change": _vm.onChange } })], 1)], 1);
};
var staticRenderFns$2 = [];
const apiUrl$1 = "#{VUE_APP_API_URL}#";
const __vue2_script$2 = {
  components: {
    ViewsKingstreetSteriflow: ViewsKingstreetSteriflow$1
  },
  props: {
    canEdit: {
      type: Boolean,
      required: true
    },
    methodId: {
      type: Number,
      required: true
    },
    sterilizationData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      apiUrl: apiUrl$1
    };
  },
  methods: {
    onChange(e) {
      this.$emit("change", e);
    }
  }
};
const __cssModules$2 = {};
var __component__$2 = /* @__PURE__ */ normalizeComponent(__vue2_script$2, render$2, staticRenderFns$2, false, __vue2_injectStyles$2, null, null, null);
function __vue2_injectStyles$2(context) {
  for (let o in __cssModules$2) {
    this[o] = __cssModules$2[o];
  }
}
var ViewsKingstreetSteriflow = /* @__PURE__ */ function() {
  return __component__$2.exports;
}();
var __vue2_script$1 = {
  name: "EditDataEntry",
  components: {
    ListAttachments,
    EditRotaryRetort,
    ViewCommonRetort
  },
  inject: ["can", "resourceProps"],
  props: {
    isSaved: {
      type: Boolean,
      required: true
    },
    bsItemCode: {
      type: String,
      default: null
    }
  },
  data() {
    const [dataEntriesProps, attachmentsProps, britestackProps] = this.resourceProps;
    const trialFlag = "none";
    const isAdd = false;
    return {
      attachModalVisible: false,
      attachmentsProps,
      britestackProps,
      dataEntriesProps,
      isDuplicating: false,
      isInited: false,
      isSaving: false,
      showActions: false,
      isKeepAttachments: false,
      trialFlag,
      isAdd,
      isDisabled: false,
      bsAttachmentMenuItems: [{
        title: "Master Files",
        path: ""
      }, {
        title: "Britestack",
        path: "/sterilization-specifications/britestacks"
      }],
      dataEntryMenuItems: [{
        title: "Data Entry",
        path: ""
      }, {
        title: "Sterilisation Data",
        path: "/sterilization-specifications/data-entries"
      }],
      redirect: this.$route.query.redirect
    };
  },
  computed: {
    attachments() {
      return this.attachmentsProps.crud.getList();
    },
    attachmentsCustomUrl() {
      return `${this.attachmentsProps.apiUrl}/sterilization/data-entries/${this.$route.params.id}/attachments`;
    },
    canDeactivate() {
      if (this.entity.isDraft || this.entity.isInactive)
        return false;
      if (this.can(DELETE_PERMISSION))
        return true;
      if (this.$can(DELETE_PERMISSION, STERILIZATION_SPECIFICATIONS_DATA_ENTRY_NO_OR_TRIAL_ITEMS) && (!this.attachments.length || this.hasOnlyTrialAttachments))
        return true;
      return false;
    },
    canEdit() {
      if (this.entity.isInactive)
        return false;
      if (this.can(MAINTAIN_PERMISSION))
        return true;
      if (this.$can(MAINTAIN_PERMISSION, STERILIZATION_SPECIFICATIONS_DATA_ENTRY_NO_OR_TRIAL_ITEMS) && (!this.attachments.length || this.hasOnlyTrialAttachments))
        return true;
      return false;
    },
    canEditPage2Notes() {
      if (this.entity.isInactive)
        return false;
      if (this.can(MAINTAIN_PERMISSION) || this.$can(EDIT_PERMISSION, STERILIZATION_SPECIFICATIONS_DATA_ENTRY_NOTES2))
        return true;
      return false;
    },
    canViewAttachments() {
      return this.$can(VIEW_PERMISSION, STERILIZATION_SPECIFICATIONS_DATA_ENTRY_ATTACHMENT);
    },
    entity() {
      let entity = this.dataEntriesProps.crud.getEntity(this.$route.params.id) || {};
      if (entity.stackingPattern) {
        const {
          stackingPattern
        } = entity;
        const newLinePos = stackingPattern.indexOf("\n");
        return __spreadProps(__spreadValues({}, entity), {
          stackingPatternName: stackingPattern.substring(0, newLinePos),
          stackingPatternDes: stackingPattern.substring(newLinePos)
        });
      }
      return entity;
    },
    hasOnlyTrialAttachments() {
      return this.attachments.every((attachment) => attachment.trialFlag);
    },
    isDraft() {
      return this.entity.isDraft;
    },
    menuItems() {
      if (this.bsItemCode)
        return this.bsAttachmentMenuItems;
      return this.dataEntryMenuItems;
    },
    methodNotes() {
      return {
        operatorValues: this.entity.operatorValues,
        processInfo: this.entity.processInfo,
        notes: this.entity.notes,
        p2NoteHeading: this.entity.p2NoteHeading,
        p2NoteText: this.entity.p2NoteText,
        p2NoteFooter: this.entity.p2NoteFooter
      };
    }
  },
  watch: {
    entity(newVal) {
      const {
        isDraft
      } = newVal;
      this.showActions = isDraft;
      if (isDraft && this.canEdit) {
        this.$emit("update:is-saved", false);
      }
      Object.keys(newVal).forEach((key) => this.dataEntriesProps.crud.updateEntity("editForm", {
        resourceKey: key,
        value: this.entity[key]
      }));
    }
  },
  async created() {
    const fetchPromises = [this.dataEntriesProps.crud.fetchEntity()];
    if (this.$can(VIEW_PERMISSION, STERILIZATION_SPECIFICATIONS_DATA_ENTRY_ATTACHMENT)) {
      fetchPromises.push(this.attachmentsProps.crud.fetchList(this.attachmentsCustomUrl));
    }
    await Promise.all(fetchPromises);
    this.dataEntryMenuItems = [...this.dataEntryMenuItems, {
      title: `${this.entity.method} - ${this.entity.code}`,
      path: ""
    }];
    if (this.bsItemCode) {
      this.bsAttachmentMenuItems = [...this.bsAttachmentMenuItems, {
        title: this.bsItemCode,
        path: `${this.britestackProps.redirectRoute}`
      }, {
        title: `${this.entity.method} - ${this.entity.code}`,
        path: ""
      }];
    }
    this.isInited = true;
  },
  methods: {
    async deactivate() {
      await this.dataEntriesProps.crud.submitPartialEntity({
        resourceName: this.dataEntriesProps.resourceName,
        id: this.entity.id,
        data: [{
          op: "replace",
          path: "/IsInactive",
          value: true
        }]
      });
      this.$router.push("/sterilization-specifications/data-entries");
    },
    async active() {
      await this.dataEntriesProps.crud.submitPartialEntity({
        resourceName: this.dataEntriesProps.resourceName,
        id: this.entity.id,
        data: [{
          op: "replace",
          path: "/IsInactive",
          value: false
        }]
      });
      this.$router.push("/sterilization-specifications/data-entries");
    },
    async duplicate() {
      this.isDuplicating = true;
      try {
        this.dataEntriesProps.crud.updateEntity("createForm", {
          resourceKey: "code",
          value: this.entity.code
        });
        this.dataEntriesProps.crud.updateEntity("createForm", {
          resourceKey: "isKeepAttachments",
          value: this.isKeepAttachments
        });
        const {
          data: newEntity
        } = await this.dataEntriesProps.crud.submitEntity("create", `${this.dataEntriesProps.apiUrl}/sterilization/data-entries/${this.entity.id}`);
        this.$router.push(`${this.dataEntriesProps.redirectRoute}/${newEntity.id}?parent=${this.entity.id}`);
        this.attachmentsProps.crud.fetchList(this.attachmentsCustomUrl);
      } finally {
        this.isDuplicating = false;
      }
    },
    getDetailViewFor(methodId) {
      switch (methodId) {
        case STERILIZATION_METHODS.CONTINUOUS_COOKER:
        case STERILIZATION_METHODS.PETFOOD_HYDROSTATIC_COOKER:
        case STERILIZATION_METHODS.HYDROSTATIC_COOKER:
          return EditCookers;
        case STERILIZATION_METHODS.ROTARY_RETORT:
        case STERILIZATION_METHODS.STATIC_RETORT_RECIPE:
        case STERILIZATION_METHODS.STATIC_RETORT_SEASONAL:
          return EditRotaryRetort;
        case STERILIZATION_METHODS.LAGARDE_RETORTS:
        case STERILIZATION_METHODS.KINGSTREET_STERIFLOW:
        case STERILIZATION_METHODS.PETFOOD_STERITECH_STERILISER:
          return ViewsKingstreetSteriflow;
        case STERILIZATION_METHODS.RECIPE_RETORT:
        case STERILIZATION_METHODS.PETFOOD_STERIFLOW_RETORT:
        case STERILIZATION_METHODS.STERIFLOW:
        case STERILIZATION_METHODS.OVERPRESSURE_RETORT:
          return ViewCommonRetort;
        case STERILIZATION_METHODS.GTC_PASTEURISER:
          return ViewGTCPasteuriser;
        default:
          return "div";
      }
    },
    save: _.debounce(async function() {
      await this.dataEntriesProps.crud.submitEntity("replace");
    }, 1e3),
    storeValue(resourceKey, value) {
      if (!resourceKey.includes(",")) {
        this.dataEntriesProps.crud.updateEntity("editForm", {
          resourceKey,
          value
        });
        return;
      }
      const resourceKeys = resourceKey.split(",");
      resourceKeys.forEach((key) => this.dataEntriesProps.crud.updateEntity("editForm", {
        resourceKey: key,
        value: value[key]
      }));
    },
    onAttachClick() {
      this.attachModalVisible = true;
    },
    onBack() {
      let redirectUrl = "/sterilization-specifications/data-entries";
      if (this.bsItemCode) {
        if (this.redirect === "EditBritestack") {
          redirectUrl = `${this.britestackProps.redirectRoute}?tab=2`;
        } else {
          redirectUrl = `${this.britestackProps.redirectRoute}/sterilizations`;
        }
      }
      this.$router.push(redirectUrl);
    },
    onChange(value, resourceKey, handleSubmit) {
      if (!this.isInited)
        return;
      this.storeValue(resourceKey, value);
      handleSubmit(this.save);
    },
    onDeactivateClick() {
      this.$confirm({
        class: "list__modal-confirm",
        icon: "eye-invisible",
        title: "Do you want to Deactivate this item?",
        cancelText: "No",
        okText: "Yes",
        okType: "danger",
        onOk: this.deactivate
      });
    },
    onActivateClick() {
      this.$confirm({
        class: "list__modal-confirm",
        icon: "eye",
        title: "Do you want to Activate this item?",
        cancelText: "No",
        okText: "Yes",
        okType: "primary",
        onOk: this.active
      });
    },
    onEditClick() {
      const h = this.$createElement;
      this.isKeepAttachments = false;
      this.$confirm({
        title: `Do you want to make a copy of ${this.entity.method} ${this.entity.code} to edit?`,
        content: h("div", {
          "class": "w-100 d-flex align-items-center"
        }, [h("input", {
          "attrs": {
            "type": "checkbox",
            "id": "chkbxIsKeep"
          },
          "class": "custom-checkbox mr-2",
          "on": {
            "change": (e) => this.isKeepAttachments = e.target.checked
          }
        }), h("label", {
          "attrs": {
            "for": "chkbxIsKeep"
          }
        }, [" Keep the attachments?"])]),
        cancelText: "Cancel",
        okText: "Yes",
        onOk: this.duplicate
      });
    },
    async onSaveClick() {
      this.isSaving = true;
      try {
        const res = await this.dataEntriesProps.crud.submitPartialEntity({
          resourceName: this.dataEntriesProps.resourceName,
          id: this.entity.id,
          data: [{
            op: "replace",
            path: "/IsDraft",
            value: false
          }]
        });
        this.dataEntriesProps.crud.fetchEntity(res.data.id);
        this.attachmentsProps.crud.fetchList(this.attachmentsCustomUrl);
        this.showActions = false;
        this.$emit("update:is-saved", true);
      } finally {
        this.isSaving = false;
      }
    },
    onCancelClick() {
      const parentId = this.$route.query.parent || "";
      const {
        apiUrl: apiUrl2,
        redirectRoute
      } = this.dataEntriesProps;
      this.dataEntriesProps.crud.destroyEntity(`${apiUrl2}/sterilization/data-entries/${this.entity.id}`, this.entity.id).then(() => {
        this.$emit("update:is-saved", true);
        this.$nextTick(() => {
          if (parentId) {
            this.$router.push(`${redirectRoute}/${parentId}`);
            this.attachmentsProps.crud.fetchList(this.attachmentsCustomUrl);
          } else {
            this.$router.push(`${redirectRoute}`);
          }
        });
      });
    },
    onComponentChange(e) {
      this.isDisabled = e;
    }
  }
};
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "kh-edit-data-entry" }, [_c("bread-crumb", { attrs: { "items": _vm.menuItems } }), _c("a-row", { staticClass: "header mb-2 px-5", attrs: { "gutter": 16, "type": "flex" } }, [_c("a-col", { staticClass: "d-flex align-items-center flex-wrap", attrs: { "span": 20 } }, [_c("a-icon", { staticClass: "arrow-left mr-3", attrs: { "type": "arrow-left" }, on: { "click": function($event) {
    return _vm.onBack();
  } } }), _c("span", { staticClass: "mr-3 h5 neutral-10--text" }, [_vm._v(_vm._s(_vm.entity.method) + " - " + _vm._s(_vm.entity.code))]), _c("span", { staticClass: "entity-color" }, [_vm._v(_vm._s(_vm._f("moment")(_vm.entity.issueDate, "DD/MM/YYYY")))]), _c("span", { staticClass: "ml-3" }, [_vm.entity.isDraft ? _c("a-tag", { attrs: { "color": "blue" } }, [_vm._v("Draft")]) : _vm._e()], 1)], 1), _c("a-col", { staticClass: "d-flex align-items-center justify-content-end", attrs: { "span": 4 } }, [_vm.canDeactivate ? _c("a-button", { attrs: { "ghost": "", "type": "danger" }, on: { "click": _vm.onDeactivateClick } }, [_c("span", [_vm._v("Deactivate")])]) : _vm._e(), _vm.entity.isInactive ? _c("a-button", { attrs: { "type": "primary" }, on: { "click": _vm.onActivateClick } }, [_c("span", [_vm._v("Activate")])]) : _vm._e(), _vm.canEdit ? _c("a-button", { staticClass: "ml-2", attrs: { "disabled": _vm.entity.isDraft, "loading": _vm.isDuplicating, "type": "primary" }, on: { "click": _vm.onEditClick } }, [_vm.entity.isDraft ? _c("span", [_vm._v("Editing...")]) : _c("span", [_vm._v("Edit")])]) : _vm._e()], 1), _c("a-col", { style: { marginLeft: "34px" } }, [_c("div", { staticClass: "neutral-7--text" }, [_vm._v(_vm._s(_vm.entity.stackingPatternName))]), _c("div", { staticClass: "neutral-7--text" }, [_vm._v(_vm._s(_vm.entity.stackingPatternDes))])])], 1), _c("a-tabs", { staticClass: "kh-tabs", attrs: { "default-active-key": "1" } }, [_c("a-tab-pane", { key: "1", staticClass: "p-5", attrs: { "tab": "General Information" } }, [_c("simple-form", { scopedSlots: _vm._u([{ key: "default", fn: function(ref) {
    var handleSubmit = ref.handleSubmit;
    return [_c("a-row", { attrs: { "gutter": 24, "type": "flex" } }, [_c("a-col", { attrs: { "lg": 24, "xl": 8 } }, [_c("a-card", { style: { height: "100%" } }, [_c("h2", { staticClass: "continuous-cooker" }, [_vm._v(_vm._s(_vm.entity.method) + " - " + _vm._s(_vm.entity.code))]), _c("a-row", { staticClass: "pt-3", attrs: { "gutter": 16 } }, [_vm.entity ? _c("a-col", { attrs: { "span": 12 } }, [_c("date-picker", { attrs: { "disabled": "", "form-item": "", "format-date": "DD/MM/YYYY", "label": "Issue Date", "rules": "required", "value": _vm.entity.issueDate }, on: { "change": function($event) {
      return _vm.onChange($event, "issueDate", handleSubmit);
    } } })], 1) : _vm._e(), _c("a-col", { attrs: { "span": 12 } }, [_c("label", [_vm._v("Previous Issue Date")]), _c("div", { staticClass: "mt-2 neutral-10--text" }, [_vm._v(_vm._s(_vm._f("moment")(_vm.entity.prevIssueDate, "DD/MM/YYYY")))])])], 1), _vm.entity ? _c("a-row", { attrs: { "gutter": 16 } }, [_c("a-col", { attrs: { "span": 24 } }, [_c("select-input", { attrs: { "disabled": !_vm.isDraft || !_vm.canEdit, "form-item": "", "label": "Stacking Pattern", "reference": "sterilization.common.stacking-patterns", "source": "id", "source-label": "description", "value": _vm.entity.stackingPatternId }, on: { "change": function($event) {
      return _vm.onChange($event, "stackingPatternId", handleSubmit);
    } } })], 1)], 1) : _vm._e(), _vm.entity ? _c("div", [_c("div", [_vm._v(" Last modified by "), _c("br"), _vm._v(" " + _vm._s(_vm.entity.updatedBy) + " on " + _vm._s(_vm._f("moment")(_vm.entity.updatedAt, "DD/MM/YYYY HH:mm")) + " ")])]) : _vm._e()], 1)], 1), _c("a-col", { staticClass: "kh-edit-data-entry__notes", attrs: { "lg": 24, "xl": 16 } }, [_c("a-card", [_c("method-notes-input", { attrs: { "page1-disabled": !_vm.isDraft || !_vm.canEdit, "page2-disabled": !_vm.canEditPage2Notes, "span": 24, "value": _vm.methodNotes }, on: { "change": function($event) {
      return _vm.onChange($event, "operatorValues,processInfo,notes,p2NoteHeading,p2NoteText,p2NoteFooter", handleSubmit);
    } } })], 1)], 1)], 1)];
  } }]) })], 1), _c("a-tab-pane", { key: "2", attrs: { "tab": "View Details" } }, [_vm.entity.methodId ? _c(_vm.getDetailViewFor(_vm.entity.methodId), { tag: "component", attrs: { "can-edit": _vm.canEdit, "data-entry-id": _vm.entity.id, "method-id": _vm.entity.methodId, "sterilization-data": _vm.entity }, on: { "change": _vm.onComponentChange } }) : _vm._e()], 1), _vm.canViewAttachments ? _c("a-tab-pane", { key: "3", attrs: { "tab": "View Attachments (" + _vm.attachments.length + ")" } }, [_c("list-attachments", { attrs: { "sterilization-data": _vm.entity, "attachments-data": _vm.attachments } })], 1) : _vm._e()], 1), _c("transition", { attrs: { "name": "slide" } }, [_vm.showActions && _vm.canEdit ? _c("a-row", { staticClass: "kh-edit-data-entry__actions p-4 neutral-1", attrs: { "justify": "end", "type": "flex" } }, [_c("a-col", [_c("a-button", { on: { "click": _vm.onCancelClick } }, [_vm._v("Cancel")]), _c("a-button", { staticClass: "ml-2", attrs: { "loading": _vm.isSaving, "disabled": _vm.isDisabled, "type": "primary" }, on: { "click": _vm.onSaveClick } }, [_vm._v("Save")])], 1)], 1) : _vm._e()], 1)], 1);
};
var staticRenderFns$1 = [];
var EditDataEntry_vue_vue_type_style_index_0_scoped_true_lang = "";
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "0ce5c106", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var EditDataEntry = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "edit-data-entries h-100" }, [_c("resource", { attrs: { "name": "sterilization.common.stacking-patterns", "api-url": _vm.apiUrl } }), _c("resource", { staticClass: "h-100", attrs: { "name": [
    "sterilization.data-entries",
    "sterilization.data-entries.attachments",
    "sterilization.britestacks"
  ], "api-url": _vm.apiUrl, "page": _vm.page, "redirect-route": [
    "/sterilization-specifications/data-entries",
    void 0,
    _vm.bsRedirectRoute
  ] } }, [_c("edit-data-entry", { attrs: { "bs-item-code": _vm.bsItemCode, "is-saved": _vm.isSaved }, on: { "update:isSaved": function($event) {
    _vm.isSaved = $event;
  }, "update:is-saved": function($event) {
    _vm.isSaved = $event;
  } } })], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    EditDataEntry
  },
  props: {
    britestackId: {
      type: String,
      default: null
    },
    bsItemCode: {
      type: String,
      default: null
    }
  },
  data() {
    let bsRedirectRoute;
    if (this.britestackId) {
      bsRedirectRoute = `/sterilization-specifications/britestacks/${this.britestackId}`;
    }
    return {
      apiUrl,
      bsRedirectRoute,
      isSaved: true,
      page: STERILIZATION_SPECIFICATIONS_DATA_ENTRY
    };
  },
  beforeRouteLeave(to, from, next) {
    if (this.isSaved) {
      next();
    } else {
      this.$notification.warning({
        message: "You are currently editing a sterilization method, please save the record before leaving."
      });
      next(false);
    }
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
